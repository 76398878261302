import React, { useContext, useMemo } from "react";
import UploadFile from "assets/images/icons/upload-file.svg";
import { Stack } from "@mui/material";
import { DashboardContext } from "utils/contexts";
import { style } from "./style";

export const FileUploader = () => {
  const { file, getRootProps, getInputProps } = useContext(DashboardContext);
  // const filePreview = useMemo(() => {
  //   <div>
  //     {file.type === "pdf" && ""}
  //     {file.type === "image" && ""}
  //     {file.type === "excel" && ""}
  //     {file.type === "csv" && ""}
  //     {file.type === "doc" && ""}
  //   </div>;
  // }, [file]);
  return (
    <>
      <Stack {...getRootProps()} sx={style.dropzone}>
        <input {...getInputProps()} />
        <img src={UploadFile} alt={"image preview"} />
        <p style={style.fileText}>Upload your file</p>
      </Stack>
      <Stack sx={{ ...style.filePreview }}>
        {file.value ? (
          <embed height={"100%"} src={file.value}></embed>
        ) : (
          <p style={style.prevText}>Preview</p>
        )}
      </Stack>
    </>
  );
};
