import { Navigate } from "react-router-dom";
import { MainLayout } from "../layout/mainLayout/main.layout";
import { Dashboard, 
  // Reports,
   Exports, Logout } from "../UI/pages";
import { ROUTES } from "utils/constants";
import Roles from "UI/pages/roles";

// Define main application routes
export const mainRoutes = (role) => {
  const adminRoutes = [
    {
      element: <MainLayout sidebar={true} />,
      children: [
        { path: ROUTES.BASE, element: <Dashboard />, exact: true },
        // { path: ROUTES.REPORTS, element: <Reports />, exact: true },
        { path: ROUTES.EXPORTS, element: <Exports />, exact: true },
        { path: ROUTES.ROLES, element: <Roles />, exact: true },
      ],
    },
  ];
  const userRoutes = [
    {
      element: <MainLayout />,
      children: [{ path: ROUTES.BASE, element: <Dashboard />, exact: true }],
    },
  ];
  const commonRoutes = [
    {
      path: ROUTES.ALL_ROUTES,
      element: <Navigate to={ROUTES.BASE} />,
      exact: true,
    },
    { path: ROUTES.LOGOUT, element: <Logout />, exact: true },
  ];

  switch (role) {
    case "admin":
      return [...adminRoutes, ...commonRoutes];
    default:
      return [...userRoutes, ...commonRoutes];
  }
};
