import React from "react";

import { Container } from "@mui/material";
import { GoogleLogin } from "UI/molecules";
import { style } from "./style";

export const LoginTemplate = () => {
  return (
    <Container sx={style.container} maxWidth="xl">
      <GoogleLogin />
    </Container>
  );
};
