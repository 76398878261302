import { Box } from '@mui/material';
import { MDTtable } from 'UI/molecules';
import { RoleHeader } from 'UI/molecules/roleHeader';
import { Fragment, useContext } from 'react'
import { muiSx } from 'themes/styles';
import { RolesContext } from 'utils/contexts/dashboardContext';
import { rolesTableColumns, rolesTableOptions } from 'utils/data/table/userTable';

const RolesTemplate=()=> {
  const { activeId,applyingRole,handleApplyRole,setScanDocPayload,userList,rolesStatus } = useContext(RolesContext);
  
  return (
    <Fragment>
      <RoleHeader/>
      <Box sx={{...muiSx.tableBox,mt:2,minHeight:'72vh',width:'100%',overflowX:'auto',px:1}}>
        <MDTtable
          columns={rolesTableColumns(activeId,applyingRole,handleApplyRole,setScanDocPayload,userList)}
          data={userList.all}
          options={rolesTableOptions(rolesStatus)}
        />
      </Box>
    </Fragment>
  )
}

export default RolesTemplate;