export const ASYNC_ROUTES = {
  // AUTH
  LOGIN: "auth/login",
  USER_PROFILE: "auth/user_profile",
  AUTH_TOKEN: "auth/token",

  // DOCUMENTS
  GET_PRESIGNED_URL: "get-presigned-url",
  UPLOAD_FILE: "upload-file",
  VALIDATE_DOCUMENT: "validate-document",
  SCAN_DOCUMENT: "scan-document",
  DOC_COUNTS: "get-counts",
  GET_JOBS: "get-jobs",
  DOCUMENT_BY_JOB_ID: "get-document-by-job-id",

  // EXPORTS
  EXPORT_LIST: "get-export-list",

  // ROLES
  USER_LIST: "get-user-list",
  APPLY_ROLE: "apply-user-role",
};

export const METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const THUNK_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  REJECTED: "reject",
};

export const SERVICE_URL = {
  // AUTH
  LOGIN: "/login",
  USER_PROFILE: "/oauth2/v2/userinfo",
  AUTH_TOKEN: "/auth-token",

  // DOCUMENTS
  GET_PRESIGNED_URL: "/presigned-url",
  VALIDATE_DOCUMENT: "/validate-document",
  SCAN_DOCUMENT: "/scan-documents",
  DOC_COUNTS: "/document-counts",
  JOBS: "/jobs",
  DOCUMENT_BY_JOB_ID: "/documents/:job_id",

  // EXPORT
  EXPORT_LIST: "/admin/export-data",

  // ROLES
  USER_LIST:'/admin/user-list',
  APPLY_ROLE:'/admin/change-role',
};
