import { METHODS, SERVICE_URL } from "utils/constants";
import { AppClient } from "utils/network";
import { filterEmptyKeys } from "utils/helper";

export const getExportList = (payload) => {
  return AppClient({
    url: SERVICE_URL.EXPORT_LIST,
    method: METHODS.GET,
    params: filterEmptyKeys(payload || {}),
  });
};