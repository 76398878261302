import { Button } from "@mui/material";
import React from "react";

export const ButtonAtom = (props) => {
  const { label, sx, ...rest } = props;
  return (
    <Button sx={sx} {...rest}>
      {label}
    </Button>
  );
};
