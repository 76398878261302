import { useRoutes } from "react-router-dom";
import { mainRoutes } from "./main.routes";
import { authRoutes } from "./auth.routes";
import { useSelector } from "react-redux";
import { AppInstance } from "utils/network";
export const Routes = ({ token }) => {
  const { user } = useSelector((state) => state.auth);
  const role = user?.role;
  AppInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return useRoutes(token ? mainRoutes(role) : authRoutes);
};
