import { createSlice } from "@reduxjs/toolkit";
import { getDocCountsAsynThunk, scanDocAsynThunk } from "redux/asyncThunk";
import { THUNK_STATUS } from "utils/constants";

const initialState = {
  documentList: {
    current: {},
    all: [],
  },
  documentListStatus: null,
  doc_counts: null,
};

export const DocSlice = createSlice({
  name: "document",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(scanDocAsynThunk.pending, (state) => {
        state.documentListStatus = THUNK_STATUS.PENDING;
      })
      .addCase(scanDocAsynThunk.fulfilled, (state, action) => {
        let data = action.payload.data?.Items || [];
        data = data.filter(item=>Object.keys(item).length>1);
        if (
          action.payload.config.params?.sk &&
          action.payload.config.params?.username &&
          action.payload.data?.Items
        ) {
          state.documentList.all.push(...data);
        } else {
          state.documentList.all = data;
        }
        state.documentList.current = action.payload.data;
        state.documentListStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(scanDocAsynThunk.rejected, (state, action) => {
        state.documentListStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(getDocCountsAsynThunk.fulfilled, (state, action) => {
        state.doc_counts = action.payload.data;
      });
  },
});

export const authState = (state) => DocSlice;
export default DocSlice.reducer;
