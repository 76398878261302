import React, { useContext } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Button,
  Divider,
  IconButton,
  Grid,
  TextField,
  Badge,
  Box,
} from "@mui/material";
import { style } from "./style";
import { AutocompleteAtom } from "UI/atoms";
import { documentStatusData, visaTypes } from "utils/data";
import { ExportsContext } from "utils/contexts";
import { filterEmptyKeys, formatDateWithDash } from "utils/helper";
import _ from "lodash";
import moment from "moment";

export function ExportFilterPopover() {
  const {
    handleRemoveFilter,
    handleEndDateChange,
    handleStartDateChange,
    handleVisaTypeChange,
    handleStatusChange,
    handleFilterClose,
    handleFilterClick,
    autoCompleteValue,
    filterAnchorEl,
    handleApplyFilter,
    filter,
  } = useContext(ExportsContext);
  return (
    <Box display='flex' alignItems='center' justifyContent='center'>
      <IconButton onClick={handleFilterClick}>
        {(filter.verification_output || filter.visa_type) ? (
          <Badge color="error" variant="dot">
            <FilterListIcon />
          </Badge>
        ) : (
          <FilterListIcon />
        )}
      </IconButton>
      <Popover
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid container sx={style.filterPopover}>
          <Grid item xs={12}>
            <Typography variant="h5">Filter List</Typography>
            <Divider />
          </Grid>
          <Grid container pt={2} spacing={2}>
            <Grid item xs={12} sm={6}>
              <AutocompleteAtom
                label={"Status"}
                options={documentStatusData}
                onChange={handleStatusChange}
                value={autoCompleteValue.verification_output}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <AutocompleteAtom
                label={"Visa Type"}
                options={visaTypes}
                onChange={handleVisaTypeChange}
                value={autoCompleteValue.visa_type}
              />
            </Grid>
            <Grid item mb={-1.5} xs={12}>
              <Typography>Date Range</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={"Start Date"}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  inputProps: {
                    max: moment(new Date()).format("YYYY-MM-DD")
                  },
                }}
                type="date"
                size="small"
                onChange={handleStartDateChange}
                value={filter.from}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={"End Date"}
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
                size="small"
                onChange={handleEndDateChange}
                value={filter.to}
                InputProps={{
                  inputProps: {
                    min: filter.from,
                    max: moment(new Date()).format("YYYY-MM-DD")
                  },
                }}
                disabled={!filter.from}
              />
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ bgcolor: "primary.light" }}
                  onClick={handleApplyFilter}
                  disabled={!filter.to}
                >
                  Apply Filters
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  color="error"
                  onClick={handleRemoveFilter}
                  disabled={!(filter?.verification_output|| []).length && !(filter?.visa_type || []).length}
                >
                  Remove Filters
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </Box>
  );
}
