import React, { Fragment, useContext } from "react";
import { Box, Stack } from "@mui/material";
import { muiSx } from "../../../themes/styles";
import { dashboardTableColumns, dashboardTableOptions } from "utils/data/table";
import { DashboardContext } from "utils/contexts";
import {
  DashboardHeader,
  UploadDocDialog,
  MDTtable,
  DocumentDetailsDialog,
  CurrentDocStatus,
  PendingJobsDialog,
} from "../../molecules";

export const DashboardTemplate = () => {
  const {
    showStatus,
    documentList,
    handleDocDetailsDialogOpen,
    setScanDocPayload,
    documentListStatus,
  } = useContext(DashboardContext);

  return (
    <Fragment>
      <CurrentDocStatus />
      <DashboardHeader />
      <Box sx={muiSx.tableBox} mt={2}>
        <MDTtable
          columns={dashboardTableColumns(
            showStatus,
            documentList,
            handleDocDetailsDialogOpen,
            setScanDocPayload
          )}
          data={documentList.all}
          options={dashboardTableOptions(documentListStatus)}
        />
      </Box>
      <UploadDocDialog />
      <DocumentDetailsDialog context={DashboardContext}/>
      <PendingJobsDialog />
    </Fragment>
  );
};
