import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import HourglassBottomRoundedIcon from "@mui/icons-material/HourglassBottomRounded";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { DocDetailsValue } from "./docDetailsValue";
import { Grid, Stack } from "@mui/material";
import { useContext } from "react";
import { DashboardContext } from "utils/contexts";
import { formatDateWithDateObject, trimFilename } from "utils/helper";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export function PendingJobsDialog() {
  const {
    pendingJobsData,
    openPendingJobsDialog,
    handlePendingJobsDialogClose,
  } = useContext(DashboardContext);

  return (
    <>
      <BootstrapDialog
        onClose={handlePendingJobsDialogClose}
        aria-labelledby="customized-dialog-title"
        open={openPendingJobsDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Stack gap={1} direction={"row"} alignItems={"center"}>
            <HourglassBottomRoundedIcon />
            <Typography>{pendingJobsData?.status==='Failed' ? "This job has " + (pendingJobsData?.status || "").toLowerCase() : "This job is " + (pendingJobsData?.status || "").toLowerCase()}</Typography>
          </Stack>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handlePendingJobsDialogClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Grid pt={2} container rowSpacing={0.5} columnSpacing={2}>
            <DocDetailsValue
              title={"File Name"}
              value={trimFilename(pendingJobsData?.file_name || "")}
            />
            <DocDetailsValue
              title={"Created At"}
              value={formatDateWithDateObject(pendingJobsData?.created_at)}
            />
            <DocDetailsValue title={"Mode"} value={pendingJobsData?.mode} />
            <DocDetailsValue title={"Status"} value={pendingJobsData?.status} />
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </>
  );
}
