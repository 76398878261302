import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { googleLogout } from "@react-oauth/google";
import { logout } from "redux/slices/auth.slice";

export const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    googleLogout();
    dispatch(logout());
  });
};
