import React, { Fragment, useContext, useState } from "react";
import { Box, Stack, Typography,IconButton, CircularProgress,Grid,TextField } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ButtonAtom } from "UI/atoms";
import { muiSx } from "themes/styles";
import { dashboardTableOptions } from "utils/data/table";
import { ExportsContext } from "utils/contexts";
import {
  DocumentDetailsDialog,
  MDTtable
} from "../../molecules";
import { formatDateWithDash, formatDateWithDateObject } from "utils/helper";
import { ExportHeader } from "UI/molecules/exportHeader";
import moment from "moment";

export const ExportsTemplate = () => {
  const {
    showStatus,
    exportDocuments=[],
    handleDocDetailsDialogOpen,
    exportDocumentStatus,
    actualFilter,
  } = useContext(ExportsContext);

  const [logData,setLogData]=useState([]);

  const columns = [
    {
      name: "receipt_number",
      label: "Receipt No.",
      options: {
        filter: false,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
      },
    },
    {
      name: "name",
      label: "Candidate Name",
      options: {
        filter: true,
        filterType: "dropdown",
        filterOptions: { fullWidth: true },
      },
    },
    {
      name: "created_at",
      label: "Upload date",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <>{formatDateWithDateObject(value)}</>;
        },
      },
    },
    {
      name: "visa_type",
      label: "Visa type",
      options: {
        filter: true,
        filterType: "dropdown",
        customBodyRender: (value) => {
          return <Box sx={{ whiteSpace: "nowrap" }}>{value}</Box>;
        },
      },
    },
    {
      name: "verification_output",
      label: "Result",
      options: {
        filter: true,
        filterType: "dropdown",
        sort: false,
        setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
        customBodyRender: (value, meta) => {
          let rowIndex = meta.rowIndex;
          return (
            <Stack
              sx={{ whiteSpace: "nowrap" }}
              direction={"row"}
              spacing={1}
              alignItems={"center"}
            >
              {showStatus(value)}
              <IconButton
                onClick={() => handleDocDetailsDialogOpen(rowIndex)}
              >
                <VisibilityIcon />
              </IconButton>
            </Stack>
          );
        },
      },
    },
  ];

  return (
    <Fragment>
      <ExportHeader/>
      <Box sx={{...muiSx.tableBox,mt:2,minHeight:'68vh',width:'100%',overflowX:'auto',px:1}}>
        {actualFilter.from && actualFilter.to
          ?
          <Typography variant="body2" textAlign='center'>Showing result from {moment(actualFilter.from).format('MMMM Do, YYYY')} to {moment(actualFilter.to).format('MMMM Do, YYYY')} </Typography>
          :
          null
        }
        <MDTtable
          columns={columns}
          data={exportDocuments}
          options={dashboardTableOptions(exportDocumentStatus)}
        />
      </Box>
      <DocumentDetailsDialog context={ExportsContext} />
    </Fragment>
  );
};
