import React, { useContext } from "react";
import { Container, Typography, Stack } from "@mui/material";
import { ButtonAtom } from "UI/atoms";
import HomeBackground from "assets/images/dashboard_preview.png";
import searchIcon from "assets/images/search.png";
import settingsIcon from "assets/images/settings.png";
import verifiedIcon from "assets/images/verified.png";
import { HomeContext } from "utils/contexts";
import { style } from "./style";

export const HomeSection = () => {
  const { NavigateToLogin } = useContext(HomeContext);
  return (
    <Container component="section" id="home" maxWidth="xl">
      <Stack sx={style.homeSecContainer}>
        <Stack sx={style.homeSecLeft}>
          <Typography variant="h1" color="secondary">
            Stay compliant and confident in your hiring decisions
          </Typography>
          <ButtonAtom
            variant="contained"
            sx={style.loginButton}
            label={"Login"}
            onClick={NavigateToLogin}
          />
        </Stack>
        <Stack sx={style.homeSecRight}>
          <img
            style={{ width: "90%",borderRadius:10 }}
            src={HomeBackground}
            alt={"background"}
          />
          <img
            style={{ width: 70,height: 70,position: "absolute",left:10,top:0}}
            src={searchIcon}
            alt={"search-icon"}
          />
          <img
            style={{ width: 70,height: 70,position: "absolute",left:10,bottom:'20%'}}
            src={settingsIcon}
            alt={"settings-icon"}
          />
          <img
            style={{ width: 70,height: 70,position: "absolute",right:10,top:'30%'}}
            src={verifiedIcon}
            alt={"verified-icon"}
          />
        </Stack>
      </Stack>
    </Container>
  );
};
