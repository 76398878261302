import { toast } from "react-hot-toast";
import { logout } from "redux/slices/auth.slice";

export function handleError(error, store) {
  if (error.request.status === 401) {
    store.dispatch(logout());
    localStorage.clear();
  }
  let message;
  if (error.request.status >= 400 && error.request.status <= 499)
    message =
      error.response?.data?.message ||
      error.response?.data?.error ||
      error.response?.message;
  else if (error.request.status === 0) message = "Server not started yet :)";
  else if (error.request.status > 499) message = "Internal Server Error";
  message = message || error.message;
  switch ((message || "").toLowerCase()) {
    case "jwt malformed":
      message = "Session Expired! please login again";
      break;
    case "authorization header is missing":
      message = "You are not authorized!";
      break;
  }
  toast.error(message);
  return Promise.reject(error);
}

export function handleResponse(response) {
  // const message = response?.data?.message || "Created Successfully!";
  // if (response.config.method === "post" && message) toast.success(message);
  // if (response.config.method === "delete")
  //   toast.success("Deleted Successfully!");
  // if (response.config.method === "put") toast.success("Updated Successfully!");
  return Promise.resolve(response);
}
