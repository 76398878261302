import { METHODS, SERVICE_URL } from "utils/constants";
import { AppClient } from "utils/network";
import { filterEmptyKeys } from "utils/helper";

export const getUserList = (payload) => {
  return AppClient({
    url: SERVICE_URL.USER_LIST,
    method: METHODS.GET,
    params: filterEmptyKeys(payload || {}),
  });
};

export const applyUserRole = (payload) => {
  return AppClient({
    url: SERVICE_URL.APPLY_ROLE,
    method: METHODS.PUT,
    params: filterEmptyKeys(payload || {}),
  });
};