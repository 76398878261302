import { Outlet } from "react-router-dom";
import Header from "./Header";
import { Box, Grid, Stack } from "@mui/material";
import { muiSx } from "themes/styles";
import Sidebar from "./Sidebar";

// Main layout component for rendering nested routes
export const MainLayout = ({ sidebar = false }) => {
  return (
    <Box sx={muiSx.mainLayoutBox}>
      <Header />
      <Box sx={{display:'flex',flexDirection:'row',width:'100%'}}>
        {sidebar
          ?
          <Sidebar />
          :
          null
        }
        <Box sx={{width: {xs: '100%',md: sidebar ? 'calc(100% - 100px)' : '100%'},px:2}}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
