import axios from "axios";
import { handleResponse, handleError } from "utils/network";

// Create an Axios instance for authentication-related requests
export const AuthInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});
AuthInstance.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => handleError(error)
);

// Create an Axios instance for application-related requests
export const AppInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Authentication client for making HTTP requests with AuthInstance
export const AuthClient = ({
  url,
  method,
  data = {},
  params = {},
  headers = {},
}) => {
  return new Promise((resolve, reject) => {
    AuthInstance.request({ url, method, data, headers, params })
      .then((res) => {
        return resolve(res);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

// Application client for making HTTP requests with AppInstance
export const AppClient = ({
  url,
  method,
  data = {},
  params = {},
  headers = {},
}) => {
  return new Promise((resolve, reject) => {
    AppInstance.request({ url, method, data, headers, params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// Google Api Client Instance
export const GoogleApiClientInstance = axios.create({
  baseURL: "https://www.googleapis.com", // Google API base URL
  headers: {
    "Content-Type": "application/json",
  },
});

export const GoogleClient = ({
  url,
  method,
  data = {},
  params = {},
  headers = {},
}) => {
  return GoogleApiClientInstance.request({
    url,
    method,
    data,
    headers,
    params,
  });
};

export { handleResponse, handleError } from "./api";
