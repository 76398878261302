import { Grid, Typography } from "@mui/material";
import React from "react";
import { style } from "./style";

export const DocDetailsValue = ({ title, value }) => {
  return (
    <Grid container item xs={12} sm={6}>
      <Grid item xs={6}>
        <Typography sx={style.docTitle}>{title}</Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography sx={style.docValueOpacity} variant="captions">
          {value === "Invalid Date" || !value ? "--" : value}
        </Typography>
      </Grid>
    </Grid>
  );
};
