// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme) {
  return {
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "18px",
          color: "#000",
          border: "none",
          textAlign: "center",
        },
        head: {
          color: "#A1AEB7",
          fontSize: "18px",
          fontWeight: 500,
        },
      },
    },
  };
}
