import React, { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Container, Typography, Stack, Box } from "@mui/material";
import { HomeContext } from "utils/contexts";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import { SwiperCard } from ".";
import { SwiperData } from "utils/data";
import { style } from "./style";

export const CustomerReviews = () => {
  const { isMatchMd, isMatchSM } = useContext(HomeContext);
  return (
    <Container component="section" id="casestudies" maxWidth="xl">
      <Stack py={{ xs: 3, md: 9 }} gap={4}>
        <Typography variant="h1" sx={style.centerText}>
          Learning from Our Customers
        </Typography>
        <Box>
          <Swiper
            slidesPerView={isMatchMd ? (isMatchSM ? 1 : 2) : 3}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {SwiperData.map((swip, ind) => {
              const { backgroundImage, desc, title, role } = swip;
              return (
                <SwiperSlide key={ind}>
                  <SwiperCard {...{ backgroundImage, desc, title, role }} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
      </Stack>
    </Container>
  );
};
