import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import CrossIcon from "assets/images/icons/cross-sec.svg";
import { DashboardContext } from "utils/contexts";
import { useContext } from "react";
import { style } from "./style";
import { formatDateWithDateObject } from "utils/helper";
import { convertStatusText } from "utils/helper";
import {
  Stack,
  Typography,
  IconButton,
  Divider,
  Grid,
  CircularProgress,
  Box,
  Alert,
  AlertTitle,
} from "@mui/material";
import { DocDetailsValue } from "./docDetailsValue";

const Transition = React.forwardRef(function Transition({ ...props }, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function DocumentDetailsDialog({context}) {
  const { docDetailsDialog, docDetailsData, handleDocDetailsDialogClose } =
    useContext(context);

  return (
    <Dialog
      open={docDetailsDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleDocDetailsDialogClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth="md"
      PaperProps={{
        sx: { ...style.docDialogPaper },
      }}
    >
      <Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          gap={4}
          pb={1}
        >
          <Stack>
            <Typography variant="h3">Document Details</Typography>
          </Stack>
          <Stack>
            <IconButton onClick={handleDocDetailsDialogClose}>
              <img width={20} src={CrossIcon} alt="cross icon" />
            </IconButton>
          </Stack>
        </Stack>
        <Divider />
      </Stack>
      <Box sx={style.docDialogHeight}>
        {docDetailsData ? (
          <Box sx={{width:'100%'}}>
            <Grid pt={2} container rowSpacing={0.5} columnSpacing={2}>
              <DocDetailsValue
                title={"Receipt Number"}
                value={docDetailsData.receipt_number}
              />
              <DocDetailsValue
                title={"Visa Type"}
                value={docDetailsData.visa_type}
              />
              <Grid container item xs={12} sm={6}>
                <Grid item xs={6}>
                  <Typography sx={style.docTitle}>Result</Typography>
                </Grid>
                <Grid item xs={6}>
                  {convertStatusText(docDetailsData.verification_output)}
                </Grid>
              </Grid>
              <DocDetailsValue title={"Status"} value={docDetailsData.status} />
              <DocDetailsValue
                title={"Candidate Name"}
                value={docDetailsData.name}
              />
              <DocDetailsValue
                title={"Family Name"}
                value={docDetailsData.family_name}
              />
              <DocDetailsValue
                title={"Country Of Citizenship"}
                value={docDetailsData.country_of_citizenship}
              />
              <DocDetailsValue
                title={"Upload Date"}
                value={formatDateWithDateObject(docDetailsData.created_at)}
              />
              <DocDetailsValue
                title={"Case Type"}
                value={docDetailsData.case_type}
              />
              <DocDetailsValue
                title={"Notice Type"}
                value={docDetailsData.notice_type}
              />
              <DocDetailsValue
                title={"Beneficiary"}
                value={docDetailsData.beneficiary}
              />
              <DocDetailsValue
                title={"Petitioner"}
                value={docDetailsData.petitioner}
              />
              <DocDetailsValue
                title={"Recieved Date"}
                value={formatDateWithDateObject(docDetailsData.received_date)}
              />
              <DocDetailsValue
                title={"Notice Date"}
                value={formatDateWithDateObject(docDetailsData.notice_date)}
              />
              <DocDetailsValue
                title={"Valid From Date"}
                value={formatDateWithDateObject(docDetailsData.valid_from_date)}
              />
              <DocDetailsValue
                title={"Valid To Date"}
                value={formatDateWithDateObject(docDetailsData.valid_to_date)}
              />
              <DocDetailsValue
                title={"Until From Date"}
                value={formatDateWithDateObject(docDetailsData.until_from_date)}
              />
              <DocDetailsValue
                title={"Until To Date"}
                value={formatDateWithDateObject(docDetailsData.until_to_date)}
              />
              <DocDetailsValue title={"Class"} value={docDetailsData.class} />
            </Grid>
            {docDetailsData?.reason ?<Alert severity="error" sx={{my:2}}>
              <AlertTitle>Reason</AlertTitle>
                {docDetailsData.reason}
              </Alert>
            : null}
          </Box>
        ) : (
          <Stack sx={style.docDetailsLoader}>
            <CircularProgress />
          </Stack>
        )}
      </Box>
    </Dialog>
  );
}
