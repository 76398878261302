// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function MDTtable(theme) {
  return {
    MuiToolbar: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          whiteSpace: "nowrap",
        },
      },
    },
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          padding: "0 !important",
          paddingBottom: "40px !important",
          borderBottom: "1px solid #D6E0E6",
        },
      },
    },
  };
}
