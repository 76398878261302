import React from "react";
import { muiSx } from "themes/styles";
import { useLocation } from "react-router-dom";
import { Box, Container, Grid, Link, MenuItem } from "@mui/material";
import { HeadLogo, HeadNavigationLinks } from "UI/molecules";
import { ROUTES } from "utils/constants";
import { pages } from "utils/data";

const Header = () => {
  const location = useLocation();
  const isLoginRoute = location.pathname === ROUTES.LOGIN;
  return (
    <Container maxWidth="xl">
      <Grid item container sx={muiSx.webHeadContainer}>
        <Grid item lg={2.5} md={4} xs={4}>
          <HeadLogo isBlack={!isLoginRoute} />
        </Grid>
        {!isLoginRoute &&
          pages.map((page, ind) => (
            <MenuItem key={ind}>
              <Link href={page.route} underline="none" sx={muiSx.navFont}>
                {page.text}
              </Link>
            </MenuItem>
          ))}
      </Grid>
      <Grid item container sx={muiSx.headMobContainer}>
        <Grid item lg={2.5} md={4} xs={4}>
          <HeadLogo isBlack={!isLoginRoute} />
        </Grid>
        {!isLoginRoute && (
          <Grid item>
            <Box>
              <HeadNavigationLinks href={true} menuBar={pages} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Header;
