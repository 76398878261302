import React from "react";
import { Grid, IconButton } from "@mui/material";
import { muiSx } from "../../../themes/styles";
import { menuBar, SidebarNavigation } from "../../../utils/data";
import "./style.css";
import { HeadLogo, HeadNavigationLinks } from "UI/molecules";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Header = () => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const isUser = user.role === "user";
  return (
    <Grid container sx={muiSx.mainHeadContainer}>
      {/* Logo */}
      <Grid item xs={6}>
        <HeadLogo />
      </Grid>

      {/* Menu */}
      <Grid item container xs={6} sx={muiSx.mainHeadMenuBar}>
        {menuBar.map((menu, ind) => {
          return (
            <Grid key={ind} item>
              <IconButton onClick={() => navigate("/logout")}>
                <img src={menu.icon} alt={"menu icons"} />
              </IconButton>
            </Grid>
          );
        })}
      </Grid>

      {/* responsive Menu bar */}
      <Grid item xs={6} sx={muiSx.showxsHidelg} justifyContent={"flex-end"}>
        <HeadNavigationLinks
          menuBar={isUser ? menuBar : [...SidebarNavigation, ...menuBar]}
        />
      </Grid>
    </Grid>
  );
};

export default Header;
