import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { StatusData } from "UI/molecules";
import { Chip } from "@mui/material";

export function formatDateWithDateObject(input) {
  const date = new Date(input);
  const options = { day: "numeric", month: "short", year: "numeric", hour:"numeric", minute:"numeric"};
  return date.toLocaleDateString("en-US", options);
}

export function formatDateWithDash(timestamp) {
  var date = new Date(timestamp);
  var dd_mm_yyyy = date.toLocaleDateString();
  var yyyy_mm_dd = dd_mm_yyyy.replace(/(\d+)\/(\d+)\/(\d+)/g, "$3-$2-$1");
  return yyyy_mm_dd;
}

export function convertToTitleCase(inputString) {
  // Split the input string into words
  let words = inputString.split("_");

  // Capitalize the first letter of each word
  let capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the words into a single string
  let resultString = capitalizedWords.join(" ");

  return resultString;
}

export const showStatus = (status) => {
  switch ((status || "").toLowerCase()) {
    case "fabricated":
      return (
        <StatusData
          Icon={ClearIcon}
          color={"error.main"}
          status={"Questionable"}
        />
      );
    case "not clear":
      return (
        <StatusData
          Icon={PriorityHighIcon}
          color={"warning.main"}
          status={"Not Detected"}
        />
      );
    case "genuine":
      return (
        <StatusData
          Icon={CheckIcon}
          color={"success.main"}
          status={"Not Questionable"}
        />
      );
    default:
      return (
        <StatusData
          Icon={AccessTimeIcon}
          color={"pending.main"}
          status={status}
        />
      );
  }
};

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function filterEmptyKeys(obj) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([key, value]) =>
        value !== "" &&
        value !== null &&
        value !== undefined &&
        !(typeof value === "number" && isNaN(value))
    )
  );
}

export function convertStatusText(status) {
  switch (status) {
    case "Genuine":
      return <Chip color={"success"} label={"Not Questionable"} />;
    case "Fabricated":
      return <Chip color={"error"} label={"Questionable"} />;
    case "Not Clear":
      return <Chip color={"warning"} label={"Not Detected"} />;
  }
}

// Debounce Function
export const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const cardColor = (status) => {
  switch ((status || "").toLowerCase()) {
    case "completed":
      return "success";
    case "rejected":
      return "error";
    case "failed":
      return "error";
    default:
      return "default";
  }
};

//Replace params with value
export const replaceParams = (url, data) => {
  var regex = RegExp(":(" + Object.keys(data).join("|") + ")", "g");
  return url?.replace(regex, (m, $1) => data[$1] || m);
};

export const sortJobsByDate=(myList=[])=>{
  let list = myList.sort(function(x, y){
    return y.created_at - x.created_at;
  });
  return list;
};

export const trimFilename=(fileName="")=>{
  let fileNameLength = fileName.length;
  if (fileNameLength < 55) {
    return fileName;
  } else {
    let extensionDelimiterIndex = fileName.lastIndexOf('.');
    let middleRemovedName = `${fileName.substring(0,45)}...${fileName.substring(extensionDelimiterIndex - 3)}`
    return middleRemovedName;
  }
}
