import "./App.css";
import { Routes } from "./routes";
import { useSelector } from "react-redux";
import AuthThemeCustomization from "./themes/auth";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {Fragment} from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Typography } from "@mui/material";

function App() {
  const { token } = useSelector((state) => state.auth);
  return (
    <Fragment>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AuthThemeCustomization>
            <Routes token={token} />
          </AuthThemeCustomization>
        </LocalizationProvider>
      </GoogleOAuthProvider>
    </Fragment>
  );
}

export default App;
