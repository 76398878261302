import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { SearchInput, ButtonAtom } from "../atoms";
import { muiSx } from "../../themes/styles";
import { DashboardContext } from "utils/contexts";
import { useContext } from "react";
import { FilterPopover } from ".";

export const DashboardHeader = () => {
  const { handleDocDialogOpen, doc_counts, handleDebouncedSearch, jobsData } =
    useContext(DashboardContext);

  // Temp Counts
  const completedCount = jobsData.filter(
    (item) => item.status === "Completed"
  ).length;
  const pendingCount = jobsData.filter(
    (item) => item.status === "pending"
  ).length;
  const failedCount = jobsData.filter(
    (item) => item.status === "Failed"
  ).length;
  return (
    <Grid container sx={muiSx.dashboardHeaderStack} justifyContent='space-around' mt={1}>
      <Grid container md={3} xs={12} alignItems='center' pb={{sm:0,xs:1}}>
        <Grid item sm={10} xs={11}>
          <SearchInput
            label={"Search"}
            sx={muiSx.searchInput}
            onChange={handleDebouncedSearch}
          />
        </Grid>
        <Grid item sm={2} xs={1} alignItems='center'>
          <FilterPopover />
        </Grid>
      </Grid>
      <Grid container md={7} xs={12} alignItems='center' justifyContent='space-around' px={1} py={2}>
        <Grid container xs={7} position='relative' bgcolor='#84c88730' borderRadius={4} justifyContent='center' pt={1.5} pb={1}>
          <Typography sx={{color:'#fff',backgroundColor:'#84c887',padding:"4px 10px",borderRadius:"5px",position:'absolute',top:"-15px"}}>Results</Typography>
          <Grid item xs={3.75} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <Typography variant="h1">{doc_counts?.fabricated || "0"}</Typography>
            <Typography sx={{whiteSpace:'nowrap',fontSize:12}}>
              Questionable
            </Typography>
          </Grid>
          <Grid item xs={4.5} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <Typography variant="h1">{doc_counts?.genuine || "0"}</Typography>
            <Typography sx={{whiteSpace:'nowrap',fontSize:12}}>
              Not Questionable
            </Typography>
          </Grid>
          <Grid item xs={3.75} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <Typography variant="h1">{doc_counts?.notClear || "0"}</Typography>
            <Typography sx={{whiteSpace:'nowrap',fontSize:12}}>
              Not Detected
            </Typography>
          </Grid>
        </Grid>
        <Grid container xs={4.5} position='relative' bgcolor='#8213E010' borderRadius={4} justifyContent='center' pt={1.5} pb={1}>
        <Typography sx={{color:'#fff',backgroundColor:'#AF06F2',padding:"4px 10px",borderRadius:"5px",position:'absolute',top:"-15px"}}>Jobs</Typography>
          <Grid item xs={4} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <Typography variant="h1">{pendingCount}</Typography>
            <Typography sx={{whiteSpace:'nowrap',fontSize:12}}>
              Pending
            </Typography>
          </Grid>
          <Grid item xs={4} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <Typography variant="h1">{completedCount}</Typography>
            <Typography sx={{whiteSpace:'nowrap',fontSize:12}}>
              Completed
            </Typography>
          </Grid>
          <Grid item xs={4} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <Typography variant="h1">{failedCount}</Typography>
            <Typography sx={{whiteSpace:'nowrap',fontSize:12}}>
              Failed
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container md={2} xs={12} alignItems='center' pb={{md:0,xs:2}}>
        <ButtonAtom
          fullWidth
          sx={muiSx.uploadButton}
          variant={"contained"}
          label={"Upload Document"}
          onClick={handleDocDialogOpen}
        />
      </Grid>
    </Grid>
  );
};
