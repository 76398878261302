import { createAsyncThunk } from "@reduxjs/toolkit";
import { ASYNC_ROUTES } from "../../utils/constants";
import { authTokenService, getProfileService, loginService } from "../services";

export const loginAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.LOGIN,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await loginService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAuthTokenAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.AUTH_TOKEN,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await authTokenService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUserProfileAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.USER_PROFILE,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getProfileService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
