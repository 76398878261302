import Header from "./Header";
import Background from "../../assets/images/background.svg";
import { Outlet, useLocation } from "react-router-dom";
import { Box, Stack } from "@mui/material";
import { muiSx } from "themes/styles";
import { ROUTES } from "utils/constants";

// Auth layout component for rendering nested routes
export const AuthLayout = () => {
  const location = useLocation();
  const isLoginRoute = location.pathname.startsWith(ROUTES.LOGIN);
  return (
    <Stack sx={{ minHeight: "100vh" }}>
      <Header />
      <Stack flex={1}>
        <Outlet />
      </Stack>
      {isLoginRoute && (
        <Box sx={muiSx.loginBackground(isLoginRoute)}>
          <img
            style={{ width: "90%" }}
            alt={"background img"}
            src={Background}
          />
        </Box>
      )}
    </Stack>
  );
};
