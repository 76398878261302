import React from "react";
import { useDispatch } from "react-redux";
import { LoginTemplate } from "UI/templates";
import { LoginContext } from "utils/contexts";
import { getAuthTokenAsyncThunk } from "redux/asyncThunk";
import { toast } from "react-hot-toast";

export const Login = () => {
  const dispatch = useDispatch();
  const onSuccess = (googleLoginResponse) => {
    const loginToast = toast.loading("Logging...");
    dispatch(getAuthTokenAsyncThunk({ token: googleLoginResponse.credential }))
      .unwrap()
      .then(() => {
        toast.success("Logged in successfully!");
      })
      .catch((err) => {})
      .finally(() => {
        toast.dismiss(loginToast);
      });
  };
  const onError = (err) => {
    toast.error(err?.message || "Oops! something went wrong");
  };
  const loginContextValue = {
    dispatch,
    onSuccess,
    onError,
  };
  return (
    <LoginContext.Provider value={loginContextValue}>
      <LoginTemplate />
    </LoginContext.Provider>
  );
};
