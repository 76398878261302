export const style = {
  docDetailsLoader: {
    height: "100px",
    justifyContent: "center",
    alignItems: "center",
  },
  docDialogPaper: { borderRadius: 2, px: { xs: 2, md: 4 }, py: 4 },
  docDialogHeight: { maxHeight: "70vh", overflow: "auto" },
  subsDesc: {
    fontSize: "12px",
    color: "white",
  },
  footerSubLink: { fontSize: "14px", color: "white", fontWeight: 500 },
  subsEmailIcon: {
    background: "#0081FE",
    height: "100%",
    p: 2.5,
    borderRadius: "0 6px 6px 0",
    cursor: "pointer",
    color: "white",
  },
  subsEmail: {
    height: "50px",
    pl: 3.5,
    pr: 0,
    borderRadius: "6px 10px 10px 6px",
    background: "white",
  },
  subsTitle: {
    fontWeight: 700,
    color: "white",
  },
  subsBox: {
    background: "rgba(255,255,255,0.1)",
    py: 3.5,
    px: { xs: 2, sm: 6 },
    height: "100%",
    minWidth: { xs: "280px", sm: "350px" },
  },
  footerMenuLink: { fontSize: "16px", color: "white", fontWeight: 400 },
  footerMenuTitle: {
    fontWeight: 700,
    color: "white",
    pb: 2,
  },
  fileText: {
    color: "#B760FF",
    fontSize: "14px",
    fontWeight: "500",
  },
  prevText: {
    color: "#BFBBBB",
    fontSize: "14px",
    fontWeight: "500",
  },
  filePreview: {
    width: { xs: "200px", sm: "300px" },
    height: { xs: "300px", sm: "385px" },
    background: "#F5F6F6",
    borderRadius: "20px",
    border: "1px solid #C2D1D9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
    overflowX: "auto",
  },
  dropzone: {
    border: "2px dashed #cccccc",
    borderRadius: "20px",
    width: { xs: "200px", sm: "300px" },
    height: { xs: "300px", sm: "385px" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#FAF8FF",
    cursor: "pointer",
  },
  filterPopover: {
    py: 2,
    px: 3,
    maxWidth: "600px",
  },
  footerBox: {
    pt: { xs: 3, md: 9 },
    pb: { xs: 3, md: 7 },
    gap: 4,
  },
  swiperRole: {
    fontStyle: "italic",
    fontSize: "18px",
  },
  swiperTitle: {
    fontWeight: 700,
    textAlign: "left",
    fontSize: "18px",
  },
  swiperDesc: {
    textAlign: "justify",
    fontWeight: 500,
  },
  swiperContentPadding: {
    pt: 1,
    pb: 4,
    px: 2,
  },
  swiperBackground: (SwiperBackground) => {
    return {
      height: "200px",
      backgroundImage: `url(${SwiperBackground})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    };
  },
  swiperContainer: {
    minHeight: "400px",
    background: "white",
    maxWidth: "80%",
    borderRadius: "35px",
    overflow: "hidden",
    boxShadow: "4px 6px 25px rgba(0, 0, 0, 0.25)",
    mt: 2,
    mb: 10,
  },
  loginStack: {
    width: { xs: "100%", md: "55%" },
    alignItems: "center",
  },
  loginInnerStack: {
    maxWidth: "500px",
    justifyContent: "space-around",
    flex: 1,
    gap: 4,
  },
  googleLoginButton: {
    bgcolor: "primary.light",
    py: 1,
  },
  disclaimer: {
    color: "#424B5A",
    fontSize: "10px",
    maxWidth: "600px",
    textAlign: "justify",
  },
  homeSecContainer: {
    py: { xs: 3, md: 9 },
    flexDirection: { xs: "column", md: "row" },
    gap: 4,
    alignItems: "center",
  },
  homeSecLeft: {
    flex: 4,
    maxWidth: "500px",
    alignItems: { xs: "center", md: "flex-start" },
  },
  loginButton: {
    mt: { xs: 3, md: 7 },
    width: "240px",
    bgcolor: "primary.light",
  },
  homeSecRight: {
    flex: 6,
    justifyContent: "center",
    alignItems: "center",
    position:'relative',
  },
  hiwStack: {
    width: "100%",
    mt: { xs: 2, md: 5 },
    position: "relative",
    alignItems: "center",
  },
  centerText: {
    textAlign: "center",
  },
  hiwBackground: {
    flexDirection: { xs: "column", md: "row" },
    alignItems: { xs: "center", md: "flex-start" },
    mt: 5,
    mx: "auto",
  },
  hiwCard: {
    minWidth: "200px",
    maxWidth: "400px",
    alignItems: "center",
  },
  hiwCount: (first, last) => {
    return {
      width: "42px",
      height: "42px",
      background: (theme) => theme.palette.primary.darker,
      borderRadius: "50%",
      color: "white",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9,
      ...(!last && {
        "&::after": {
          content: '""',
          zIndex: -1,
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateY(-50%)",
          width: "50%",
          height: "2px",
          background: (theme) => theme.palette.primary.darker,
          display: { xs: "none", md: "block" },
        },
      }),
      ...(!first && {
        "&::before": {
          content: '""',
          zIndex: -1,
          position: "absolute",
          top: "50%",
          left: "0%",
          transform: "translateY(-50%)",
          width: "50%",
          height: "2px",
          background: (theme) => theme.palette.primary.darker,
          display: { xs: "none", md: "block" },
        },
      }),
    };
  },
  hiwCardTitle: {
    color: "#203E70",
    fontSize: "21px",
    fontWeight: 700,
    lineHeight: "31px",
    mt: 1,
  },
  hiwDesc: {
    fontSize: "14px",
    mt: 2,
    textAlign: "center",
    color: "secondary.main",
  },
  docValueOpacity: {
    opacity: 0.5,
    fontSize: "13px",
  },
  docTitle: {
    fontWeight: 500,
    mb: -0.5,
  },
};
