// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const Typography = (fontFamily) => ({
  htmlFontSize: 16,
  fontFamily,
  h1: {
    fontWeight: 700,
    fontSize: "45px",
  },
  h2: {
    fontWeight: 600,
    fontSize: "30px",
  },
  h3: {
    fontWeight: 500,
    fontSize: "40px",
  },
  h4: {
    fontWeight: 600,
    fontSize: "1.25rem",
  },
  h5: {
    fontWeight: 500,
    fontSize: "24px",
  },
  h6: {
    fontWeight: 500,
    fontSize: "1.2rem",
  },
  caption: {
    fontWeight: 400,
    fontSize: "24px",
  },
  body1: {
    fontSize: "1rem",
  },
  body2: {
    color: "#505D68",
    fontSize: "16px",
  },
  subtitle1: {
    fontSize: "16px",
  },
  subtitle2: {
    fontSize: "14px",
    fontWeight: 500,
  },
});

export default Typography;
