import { createAsyncThunk } from "@reduxjs/toolkit";
import { ASYNC_ROUTES } from "../../utils/constants";
import {
  docCountsService,
  getJobsService,
  presignedUrlService,
  scanDocService,
  uploadFileUrlService,
  validateDocService,
  getDocByJobId,
} from "../services";
import moment from "moment";

export const presignedUrlAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_PRESIGNED_URL,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await presignedUrlService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadFileAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.UPLOAD_FILE,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await uploadFileUrlService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const validateDocAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.VALIDATE_DOCUMENT,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await validateDocService(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const scanDocAsynThunk = createAsyncThunk(
  ASYNC_ROUTES.SCAN_DOCUMENT,
  async (payload, { rejectWithValue }) => {
    try {
      let data = {
        ...payload,
        params:{
          ...payload.params,
          from: moment(payload.params.from+" 00:00 am").valueOf(),
          to: moment(payload.params.to+" 11:59 pm").valueOf(),
        }
      };
      const response = await scanDocService(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDocCountsAsynThunk = createAsyncThunk(
  ASYNC_ROUTES.DOC_COUNTS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await docCountsService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getJobsAsynThunk = createAsyncThunk(
  ASYNC_ROUTES.GET_JOBS,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getJobsService();
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDocByJobIdAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.DOCUMENT_BY_JOB_ID,
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getDocByJobId(payload);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
