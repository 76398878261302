import { createAsyncThunk } from "@reduxjs/toolkit";
import { ASYNC_ROUTES } from "../../utils/constants";
import {
  applyUserRole,
  getUserList,
} from "../services";
import moment from "moment";

export const getUserListAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.USER_LIST,
  async (payload, { rejectWithValue }) => {
    try {
      let data = {
        ...payload,
        from: payload?.from ? moment(payload.from+" 00:00 am").valueOf() : "",
        to: payload?.to ? moment(payload.to+" 11:59 pm").valueOf() : "",
      };
      const response = await getUserList(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const applyUserRoleAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.APPLY_ROLE,
  async (payload, { rejectWithValue }) => {
    try {
      let data = {
        role: payload.role,
        email: payload.email,
      };
      const response = await applyUserRole(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);