import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { muiSx } from "themes/styles";
import { useNavigate } from "react-router-dom";

export const HeadNavigationLinks = ({ menuBar = [], href }) => {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const navigate = useNavigate(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
      >
        {anchorElNav ? (
          <ClearRoundedIcon color="primary" fontSize={"large"} />
        ) : (
          <MenuRoundedIcon fontSize={"large"} />
        )}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
      >
        {menuBar.map((page, ind) => (
          <MenuItem
            key={ind}
            onClick={() => {
              navigate(page.route);
            }}
            sx={muiSx.navFont}
          >
            {page.text}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
