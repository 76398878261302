import LogoutIcon from "assets/images/icons/logout.svg";
import ContractIcon from "assets/images/icons/contract.svg";
import Upload from "assets/images/icons/upload.svg";
import Iteration from "assets/images/icons/iteration.svg";
import List from "assets/images/icons/list.svg";
import SwiperBackground from "assets/images/swiper-background.svg";
import { ROUTES } from "utils/constants";
import moment from "moment";
import DashboardIcon from 'assets/images/icons/dashboard.png';
import DashboardIconActive from 'assets/images/icons/dashboardActive.png';
import ExportIcon from 'assets/images/icons/export.png';
import ExportIconActive from 'assets/images/icons/exportActive.png';
import RolesIcon from 'assets/images/icons/user.png';
import RolesIconActive from 'assets/images/icons/userActive.png';

export const menuBar = [
  {
    icon: LogoutIcon,
    text: "Logout",
    route: ROUTES.LOGOUT,
  },
];

export const statsData = [
  {
    count: 74,
    status: "VValidated",
  },
  {
    count: 94,
    status: "Not detected",
  },
  {
    count: 89,
    status: "Questionable",
  },
];

export const uploadDocInstruction = [
  "Design faster",
  "Something goes here",
  "Something goes here",
  "Something goes here",
  "Design better",
];

export const SidebarNavigation = [
  {
    text: "Dashboard",
    route: ROUTES.BASE,
    icon: DashboardIcon,
    activeIcon: DashboardIconActive, 
  },
  // {
  //   text: "Reports",
  //   route: ROUTES.REPORTS,
  // },
  {
    text: "Exports",
    route: ROUTES.EXPORTS,
    icon: ExportIcon,
    activeIcon: ExportIconActive, 
  },
  {
    text: "Roles",
    route: ROUTES.ROLES,
    icon: RolesIcon,
    activeIcon: RolesIconActive, 
  },
];

export const pages = [
  { route: "#home", text: "Home" },
  { route: "#howitworks", text: "How it works" },
];

export const howItWorksProcess = [
  {
    icon: ContractIcon,
    title: "Login",
    desc: "Login to Vvalidate using your business email.",
  },
  {
    icon: Upload,
    title: "Upload",
    desc: "Upload candidates H-1B documents on our portal.",
  },
  {
    icon: Iteration,
    title: "Process",
    desc: "Documents will be tested by our algorithm.",
  },
  {
    icon: List,
    title: "Vvalidate",
    desc: "Result can be of 3 types- Questionable, Not Questionable and Not Detected.",
  },
];

export const fileRequirementChecklists = [
  "Minimum resolution of 2mb - 1583x2048 ",
  "Maximum resolution of 5mb - 2478x3231",
  "Something goes here",
  "Something goes here",
  "Design better",
];

export const VvalidateFooterLinks = [
  {
    text: "Pricing",
  },
  {
    text: "Updates",
  },
  {
    text: "Beta",
  },
  {
    text: "Newsletter",
  },
  {
    text: "Collaborations",
  },
];
export const InformationFooterLinks = [
  {
    text: "FAQ",
  },
  {
    text: "Blog",
  },
  {
    text: "Support",
  },
];

export const CompanyFooterLinks = [
  {
    text: "About us",
  },
  {
    text: "Careers",
  },
  {
    text: "Contact us",
  },
];

export const SwiperData = [
  {
    backgroundImage: SwiperBackground,
    desc: "VValidate simplifies the hiring process significantly. It has been a game-changer for our hiring process.",
    title: "Anderson",
    role: "Business Analyst",
  },
  {
    backgroundImage: SwiperBackground,
    desc: "VValidate simplifies the hiring process significantly. It has been a game-changer for our hiring process.",
    title: "Anderson",
    role: "Business Analyst",
  },
  {
    backgroundImage: SwiperBackground,
    desc: "VValidate simplifies the hiring process significantly. It has been a game-changer for our hiring process.",
    title: "Anderson",
    role: "Business Analyst",
  },
  {
    backgroundImage: SwiperBackground,
    desc: "VValidate simplifies the hiring process significantly. It has been a game-changer for our hiring process.",
    title: "Anderson",
    role: "Business Analyst",
  },
  {
    backgroundImage: SwiperBackground,
    desc: "VValidate simplifies the hiring process significantly. It has been a game-changer for our hiring process.",
    title: "Anderson",
    role: "Business Analyst",
  },
  {
    backgroundImage: SwiperBackground,
    desc: "VValidate simplifies the hiring process significantly. It has been a game-changer for our hiring process.",
    title: "Anderson",
    role: "Business Analyst",
  },
];

export const documentStatusData = [
  { title: "Not Detected", value: "Not Clear" },
  { title: "Questionable", value: "Fabricated" },
  { title: "Not Questionable", value: "Genuine" },
];

export const visaTypes = [
  { title: "I-797A", value: "I-797A" },
  { title: "I-797B", value: "I-797B" },
  { title: "I-797C", value: "I-797C" },
];

export const formattedExcelDataOrder = [
  {
    name:"recordId",
    key:"id"
  },
  {
    name:"recDate",
    key:"created_at",
    type:'date',
  },
  {
    name:"recEmail",
    key:"email",
  },
  {
    name:"recFileName",
    key:"file_name"
  },
  {
    name:"recFileFormat",
    key:"file_name"
  },
  {
    name:"recCaseType",
    key:"case_type"
  },
  {
    name:"recVisaType",
    key:"visa_type"
  },
  {
    name:"recPetitioner",
    key:"petitioner"
  },
  {
    name:"recNoticedate",
    key:"notice_date",
    type:'date',
  },
  {
    name:"recBeneficiary",
    key:"beneficiary"
  },
  {
    name:"recStatus",
    key:"status"
  },
  {
    name:"recNoticeType",
    key:"notice_type"
  },
  {
    name:"recFirstName",
    key:"name"
  },
  {
    name:"recFamilyName",
    key:"family_name"
  },
  {
    name:"recCountryOfCitizenship",
    key:"country_of_citizenship"
  },
  {
    name:"recDateOfBirth",
    key:"date_of_birth",
    type:'date',
  },
  {
    name:"recReceiptNumber",
    key:"receipt_number"
  },
  {
    name:"recReceivedDate",
    key:"received_date",
    type:'date',
  },
  {
    name:"recValidFromDate",
    key:"valid_from_date",
    type:'date',
  },
  {
    name:"recValidToDate",
    key:"valid_to_date",
    type:'date',
  },
  {
    name:"recUntilFromDate",
    key:"until_from_date",
    type:'date',
  },
  {
    name:"recUntilToDate",
    key:"until_to_date",
    type:'date',
  },
  {
    name:"recBarcode",
    key:"barcode",
  },
  {
    name:"recScenarioOutput",
    key:"verification_output"
  },
  {
    name:"recVerificationOutput",
    key:"verification_output"
  },
  {
    name:"userId",
    key:"userid"
  },
  {
    name:"TransactionId",
    key:"job_id"
  },
];

export const exportFilename = (filter) =>{
  const f = "vvalidate_export_"+moment(filter.from).format('DD-MM-YYYY')+"-"+moment(filter.to).format('DD-MM-YYYY')+".xls";
  return f;
};

export function convertStatus(status) {
  switch (status) {
    case "Genuine":
      return "Not Questionable";
    case "Fabricated":
      return "Questionable";
    case "Not Clear":
      return "Not Detected";
    default:
      return status;
  }
}

export const formattedExcelData = (data) => {
  let header = formattedExcelDataOrder.map(item=>item.name);
  let finalData=[];
  data.map(d=>{
    const arr=[];
    formattedExcelDataOrder.map(item=>{
      let file = d['file_name'] || "";
      let fileName = "",fileFormat="";
      if(file){
        fileName = file.split('.')[0];
        fileFormat = file.split('.').length>0? file.split('.')[1] : "";
      }
      let fullName = d['name'] || "";
      let lastName = fullName.split(',')[1];
      if(d?.[item.key]){
        if(item?.type==='date'){
          arr.push(moment(d[item.key]).format('YYYY-MM-DD'));
        }else if(item?.key==='visa_type'){
          arr.push(d?.[item.key] ? d[item.key].replace(",","")  : "");
        }else if(item?.name==='recFileName'){
          arr.push(fileName);
        }else if(item?.name==='recFileFormat'){
          arr.push(fileFormat);
        }else if(item?.name==='recFirstName' && lastName){
          arr.push((lastName || "").replace(" ",""));
        }else{
          arr.push(d[item.key]);
        }
      }else{
        arr.push("");
      }
      return item;
    });
    finalData.push(arr);
    return d;
  });
  return [
    header,
    ...finalData
  ]
}

export const roleStatusData = [
  { title: "Admin", value: "admin" },
  { title: "User", value: "user" },
];