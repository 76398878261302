import { combineReducers } from "redux";
import authSlice from "./auth.slice";
import docSlice from "./document.slice";
import exportSlice from "./export.slice";
import rolesSlice from "./roles.slice";

// Combine multiple reducers into a single rootReducer
const rootReducer = combineReducers({
  auth: authSlice,
  document: docSlice,
  export: exportSlice,
  roles: rolesSlice
});

export default rootReducer;
