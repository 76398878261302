import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export function AutocompleteAtom(props) {
  const { label, options = [], size = "small", ...rest } = props;
  return (
    <Autocomplete
      multiple
      id="tags-outlined"
      getOptionLabel={(option) => option.title}
      filterSelectedOptions
      size={size}
      renderInput={(params) => <TextField {...params} label={label} />}
      options={options}
      {...rest}
    />
  );
}
