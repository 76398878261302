import { createAsyncThunk } from "@reduxjs/toolkit";
import { ASYNC_ROUTES } from "../../utils/constants";
import {
  getExportList,
} from "../services";
import moment from "moment";

export const getExportListAsyncThunk = createAsyncThunk(
  ASYNC_ROUTES.EXPORT_LIST,
  async (payload, { rejectWithValue }) => {
    try {
      let data = {
        ...payload,
        from: moment(payload.from+" 00:00 am").valueOf(),
        to: moment(payload.to+" 11:59 pm").valueOf(),
      };
      const response = await getExportList(data);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);