import React, { Fragment } from "react";
import { CustomerReviews, Footer, HomeSection, HowItWorks } from "UI/molecules";
import { Box } from "@mui/material";
import { muiSx } from "themes/styles";

export const HomeTemplate = () => {
  return (
    <Fragment>
      <HomeSection />
      <Box sx={muiSx.hiwNexampleContainer}>
        <Box sx={muiSx.howItWorksBackground}>
          <HowItWorks />
        </Box>
        {/* <ExampleAndInstruction /> */}
      </Box>
      <Box>{/* <CustomerReviews /> */}</Box>
      <Box sx={{ background: (theme) => theme.palette.primary.light }}>
        <Footer />
      </Box>
    </Fragment>
  );
};
