import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { SidebarNavigation } from "utils/data";
import { useLocation, useNavigate } from "react-router-dom";
import { muiSx } from "themes/styles";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <Box sx={muiSx.sidebar}>
      <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',gap:1.5,}}>
        {SidebarNavigation.map((nav, ind) => {
          let active = nav.route === location.pathname;
          return (
            <Tooltip title={nav?.text || ""} arrow placement="right">
              <Box key={ind} onClick={() => navigate(nav.route)} sx={active ?muiSx.sideBarButtonsActive : muiSx.sideBarButtons}>
                <img src={active? nav.activeIcon : nav.icon} style={{objectFit:'contain',width:20}} />
              </Box>
            </Tooltip>
          )
        })}
      </Box>
      {process.env.REACT_APP_ENV!=="PROD" ?
        <Typography>{process.env.REACT_APP_ENV}</Typography>
        :
        null
      }
    </Box>
  );
};

export default Sidebar;
