import MUIDataTable from "mui-datatables";

let globalOptions = {
  filterType: "checkbox",
  elevation: 0,
  selectableRows: "none",
  sort: false,
  search: false,
  download: false,
  print: false,
  filter: false,
  viewColumns: false,
  responsive: "standard",
  pagination: false,
};

export const MDTtable = ({ title, columns = [], data = [], options = [] }) => {
  globalOptions = { ...globalOptions, ...options };
  return (
    <MUIDataTable
      title={title}
      data={data}
      columns={columns}
      options={globalOptions}
    />
  );
};
