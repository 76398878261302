import React from "react";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { cardColor, formatDateWithDateObject, trimFilename } from "utils/helper";
import { style } from "./style";
import { Box, Chip, Stack, Typography } from "@mui/material";
import { muiSx } from "themes/styles";

export const RecentDocCard = ({
  file_name = "--",
  created_at = 0,
  mode,
  status = "",
  onClick,
}) => {
  return (
    <Box onClick={onClick} sx={muiSx.currentDocCard} display="flex" flexDirection="column" justifyContent="space-between">
      <Box>
        <Box display="flex" direction="row" gap={1}>
          <DescriptionOutlinedIcon color="pending" />
          <Typography sx={{wordBreak:'break-all'}} variant="body2">{trimFilename(file_name)}</Typography>
        </Box>
        <Box display="flex"  direction="row" gap={1} alignItems={"center"}>
          <AccessTimeIcon color="pending" />
          <Typography variant="body2">
            {formatDateWithDateObject(created_at)}
          </Typography>
        </Box>
      </Box>
      <Box pt={2} gap={1} display="flex" direction="row" justifyContent={"space-between"} alignItems={"center"}>
        <Chip color={cardColor(status)} label={status?.toUpperCase() || "--"} />
        <Typography sx={style.docValueOpacity} variant="captions">
          via {mode || "--"}
        </Typography>
      </Box>
    </Box>
  );
};
