import { Stack, Typography } from "@mui/material";
import React from "react";
import { muiSx } from "themes/styles";
import { useSelector } from "react-redux";

export const DashboardSubHeader = () => {
  const { user } = useSelector((state) => state.auth);
  const isUser = user?.role === "user";
  return (
    <Stack
      justifyContent={"space-between"}
      direction={{ xs: "column", md: "row" }}
      spacing={2}
      alignItems={"center"}
    >
      <Stack>
        {isUser ? (
          <Typography variant="h1">Dashboard</Typography>
        ) : (
          <Typography sx={muiSx.alignLeft} variant="h3">
            Documents Uploaded Recently
          </Typography>
        )}
        <Typography variant="body2">
          <Typography
            component="span"
            sx={{ color: "rgba(0,0,0,0.8)" }}
            variant="body2"
          >
            261 total,
          </Typography>{" "}
          list of all documents
        </Typography>
      </Stack>
    </Stack>
  );
};
