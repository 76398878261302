import { BlackLogo, PurpleLogo } from "assets";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/constants";

export const HeadLogo = ({ isBlack = true }) => {
  return (
    <Link to={ROUTES.BASE}>
      <img
        className="logo-img"
        style={{ cursor: "pointer" }}
        src={isBlack ? BlackLogo : PurpleLogo}
        alt="Logo"
      />
    </Link>
  );
};
