import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { SearchInput, ButtonAtom } from "../atoms";
import { muiSx } from "../../themes/styles";
import { ExportsContext } from "utils/contexts";
import CsvDownload from "react-json-to-csv";
import { ExportFilterPopover } from "./ExportfilterPopover";
import moment from "moment";

export const ExportHeader = () => {
  const { handleDebouncedSearch, handleExport, exportDocuments=[],filter } = useContext(ExportsContext);

  // Temp Counts
  const nqCount = exportDocuments.filter(
    (item) => (item?.verification_output || "").toLowerCase() === "genuine"
  ).length;
  const qCount = exportDocuments.filter(
    (item) => (item?.verification_output || "").toLowerCase() === "fabricated"
  ).length;
  const ndCount = exportDocuments.filter(
    (item) => (item?.verification_output || "").toLowerCase() === "not clear"
  ).length;

  return (
    <Grid container sx={[muiSx.dashboardHeaderStack,{mt:2}]} justifyContent='space-around'>
      <Grid container md={4} xs={12} alignItems='center' pb={{sm:0,xs:1}}>
        <Grid item sm={10} xs={11}>
          <SearchInput
            label={"Search"}
            sx={muiSx.searchInput}
            onChange={handleDebouncedSearch}
          />
        </Grid>
        <Grid item sm={2} xs={1} alignItems='center'>
          <ExportFilterPopover />
        </Grid>
      </Grid>
      <Grid container md={5} xs={12} position='relative' bgcolor='#84c88730' borderRadius={4} justifyContent='center' mt={1} mb={2} py={1}>
        <Grid item xs={3.75} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          <Typography variant="h1">{qCount || 0}</Typography>
          <Typography sx={{whiteSpace:'nowrap',fontSize:12}}>
            Questionable
          </Typography>
        </Grid>
        <Grid item xs={4.5} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          <Typography variant="h1">{nqCount || 0}</Typography>
          <Typography sx={{whiteSpace:'nowrap',fontSize:12}}>
            Not Questionable
          </Typography>
        </Grid>
        <Grid item xs={3.75} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          <Typography variant="h1">{ndCount || 0}</Typography>
          <Typography sx={{whiteSpace:'nowrap',fontSize:12}}>
            Not Detected
          </Typography>
        </Grid>
      </Grid>
      <Grid container md={2} xs={12} alignItems='center' pb={{sm:0,xs:2}}>
        {exportDocuments.length
          ?
          <ButtonAtom
            fullWidth
            sx={muiSx.uploadButton}
            variant={"contained"}
            label={"Export"}
            onClick={handleExport}
          />
          :
          null
        }
      </Grid>
    </Grid>
  );
};
