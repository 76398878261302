import { createSlice } from "@reduxjs/toolkit";
import { getExportListAsyncThunk } from "redux/asyncThunk";
import { THUNK_STATUS } from "utils/constants";

const initialState = {
  exportDocumentStatus: null,
  exportDocuments: [],
};

export const ExportSlice = createSlice({
  name: "export",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExportListAsyncThunk.pending, (state) => {
        state.exportDocumentStatus = THUNK_STATUS.PENDING;
      })
      .addCase(getExportListAsyncThunk.fulfilled, (state, action) => {
        let data = action?.payload?.data?.data || [];
        data = data.sort((a,b) => {
            return a.created_at < b.created_at ? -1 : 1;
        });
        state.exportDocuments = data;
        state.exportDocumentStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(getExportListAsyncThunk.rejected, (state, action) => {
        state.exportDocumentStatus = THUNK_STATUS.REJECTED;
      });
  },
});

export const authState = (state) => ExportSlice;
export default ExportSlice.reducer;
