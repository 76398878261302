import { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { applyUserRoleAsyncThunk, getUserListAsyncThunk } from 'redux/asyncThunk';
import { RolesContext } from 'utils/contexts/dashboardContext';
import { debounce } from 'utils/helper';
import RolesTemplate from 'UI/templates/roles';

const initialPayload={
  page_size:5,
}

const Roles=()=>{
  const [filter,setFilter]=useState({role:[],email:""});
  const [activeId,setActiveId] = useState(null);
  const [applyingRole,setApplyingRole] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [autoCompleteValue, setAutoCompleteValue] = useState({
    role: [],
  });
  const [scanDocPayload, setScanDocPayload] = useState(initialPayload);

  const dispatch = useDispatch();
  const { userList, rolesStatus } = useSelector((state) => state.roles);

  useEffect(() => {
    dispatch(getUserListAsyncThunk(scanDocPayload));
  }, [scanDocPayload]);

  const handleApplyRole=(role="",email="")=>{
    setActiveId(email);
    setApplyingRole(true);
    dispatch(applyUserRoleAsyncThunk({role:role,email:email}))
    .unwrap()
    .then((res) => {
      toast.success('User role updated successfully');
      dispatch(getUserListAsyncThunk(initialPayload))
      .unwrap()
      .then((res) => {
        setApplyingRole(false);
        setActiveId(null);
      })
      .catch((err) =>console.log(err));
    })
    .catch((err) =>console.log(err));
  }

  const applyFilter=()=>{
    setScanDocPayload((prev) => {
      return {
        ...filter,
        created_at:"",
        sk: "",
        username: "",
        page_size:5,
      };
    });
    handleFilterClose();
  }

  const removeFilter=()=>{
    setFilter({});
    setAutoCompleteValue({role:[]});
    setScanDocPayload(initialPayload);
    handleFilterClose();
  }

  const handleStatusChange = (e, newValue) => {
    setAutoCompleteValue((prev) => {
      return { ...prev, role: newValue };
    });
    setFilter((prev) => {
      return {
        ...prev,
        role: newValue.map((item) => item.value),
      };
    });
  };

  const handleStartDateChange = (e) => {
    setFilter((prev) => {
      return {
        ...prev,
        from: e.target.value,
        to: "",
      };
    });
  };

  const handleEndDateChange = (e) => {
    setFilter((prev) => {
      return {
        ...prev,
        to: e.target.value,
      };
    });
  };

  const handleSearch = (event) => {
    const inputValue = event.target.value;
    setAutoCompleteValue({role:[]});
    setFilter({});
    if (inputValue.length > 2) {
      setScanDocPayload(
        {
          created_at:"",
          sk: "",
          username: "",
          email: inputValue,
          // page_size:5,
        }
      );
    } else if (inputValue.length === 0) {
      setScanDocPayload(initialPayload);
    }
    handleFilterClose();
  };

  const handleDebouncedSearch = debounce(handleSearch, 1000);

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const rolesContextValue = {
    activeId,
    applyingRole,
    handleApplyRole,
    filterAnchorEl,
    filter,
    applyFilter,
    removeFilter,
    handleDebouncedSearch,
    handleFilterClose,
    handleFilterClick,
    handleStatusChange,
    setScanDocPayload,
    userList,
    rolesStatus,
    handleStartDateChange,
    handleEndDateChange,
    autoCompleteValue,
  };

  return (
    <RolesContext.Provider value={rolesContextValue}>
      <RolesTemplate/>
    </RolesContext.Provider>
  )
}

export default Roles;