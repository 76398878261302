import React from "react";
import { Container, Typography, Stack } from "@mui/material";
import { howItWorksProcess } from "utils/data";
import { style } from "./style";

export const HowItWorks = () => {
  return (
    <Container component="section" id="howitworks" maxWidth="xl">
      <Stack py={{ xs: 3, md: 6 }} gap={4}>
        <Typography variant="h1" sx={style.centerText}>
          How it Works?
        </Typography>
        <Stack className={"hiw-container"} sx={style.hiwBackground}>
          {howItWorksProcess.map((data, ind) => {
            const first = ind === 0;
            const last = ind === howItWorksProcess.length - 1;
            return (
              <Stack key={ind} sx={style.hiwCard}>
                <img height={120} src={data.icon} alt={"icons"} />
                <Stack sx={style.hiwStack}>
                  <Stack sx={style.hiwCount(first, last)}>{ind + 1}</Stack>
                </Stack>
                <Stack sx={{ maxWidth: "85%" }} alignItems={"center"}>
                  <Typography sx={style.hiwCardTitle}>{data.title}</Typography>
                  <Typography sx={style.hiwDesc}>{data.desc}</Typography>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </Container>
  );
};
