// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function Button(theme) {
  return {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 400,
        },
        contained: {
          paddingTop: "8px",
          paddingBottom: "8px",
          borderRadius: "5px",
          fontSize: "18px",
          fontWeight: 500,
        },
      },
    },
  };
}
