import React, { useEffect, useState } from "react";
import { DashboardContext } from "utils/contexts";
import { DashboardTemplate } from "../templates";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { debounce, showStatus } from "utils/helper";
import {
  getDocByJobIdAsyncThunk,
  getDocCountsAsynThunk,
  getJobsAsynThunk,
  presignedUrlAsyncThunk,
  scanDocAsynThunk,
  uploadFileAsyncThunk,
  validateDocAsyncThunk,
} from "redux/asyncThunk";
import { useDropzone } from "react-dropzone";

export const Dashboard = () => {
  // -----------------Initial Constants----------------- //
  const filterInitialState = {
    from: "",
    to: "",
    verification_output: null,
    visa_type: null,
  };

  // -----------------Hooks----------------- //
  const dispatch = useDispatch();
  const [docDialog, setDocDialog] = useState(false);
  const [docDetailsDialog, setDocDetailsDialog] = useState(false);
  const [docDetailsInd, setDocDetailsInd] = useState(-1);
  const [openPendingJobsDialog, setOpenPendingJobsDialog] = useState(false);
  const [pendingJobsData, setPendingJobsData] = useState(-1);
  const [file, setFile] = useState({});
  const { user } = useSelector((state) => state.auth);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filter, setFilter] = useState(filterInitialState);
  const [docDetailsData, setDocDetailsData] = useState({});
  const [jobsData, setJobsData] = useState([]);
  const [jobnCountApi, setJobnCountApi] = useState(false);
  const [autoCompleteValue, setAutoCompleteValue] = useState({
    verification_output: [],
    visa_type: [],
  });
  const [scanDocPayload, setScanDocPayload] = useState({
    params: {
      page_size: 5,
    },
  });
  const { doc_counts, documentList, documentListStatus } = useSelector(
    (state) => state.document
  );

  // -----------------Constants----------------- //
  const isUser = user?.role === "user";

  // -----------------Functions----------------- //
  const handleDocDialogOpen = () => {
    setDocDialog(true);
  };
  const handleDocDialogClose = () => {
    setDocDialog(false);
  };
  const handleDocDetailsDialogOpen = (ind) => {
    setDocDetailsDialog(true);
    setDocDetailsInd(ind);
  };
  const handleDocDetailsDialogClose = () => {
    setDocDetailsDialog(false);
  };
  const handlePendingJobsDialogOpen = (data) => {
    if (data.status === "Completed") {
      dispatch(getDocByJobIdAsyncThunk({ job_id: data.job_id }))
        .unwrap()
        .then((res) => {
          setDocDetailsData(res.data);
          setDocDetailsDialog(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setOpenPendingJobsDialog(true);
      setPendingJobsData(data);
    }
  };
  const handlePendingJobsDialogClose = () => {
    setOpenPendingJobsDialog(false);
  };
  const handleUpload = async () => {
    const uploadToast = toast.loading("Uploading...");
    try {
      const preRes = await dispatch(
        presignedUrlAsyncThunk({
          params: {
            fileName: file?.name || "initial.pdf",
          },
        })
      ).unwrap();

      const reader = new FileReader();
      reader.readAsArrayBuffer(file.fileObj);
      reader.onload = async() => {
        const pdfFormData = reader.result;
        await dispatch(
          uploadFileAsyncThunk({
            pdfFormData,
            url: preRes.data.url,
          })
        ).unwrap();
  
        const res = await dispatch(
          validateDocAsyncThunk({
            key: preRes.data.key,
          })
        ).unwrap();
  
        setScanDocPayload((prev) => {
          return {
            ...prev,
            params: {
              ...prev.params,
              sk: "",
              username: "",
            },
          };
        });
        // setJobnCountApi((prev) => !prev);
        toast.dismiss(uploadToast);
        console.log("result---->", res);
        if(res.data.job_id){
          toast.success("Document Uploaded Successfully!!");
          jobsData.unshift(res.data);
        }else{
          toast.error(res?.data?.message === "File already processed" ? "The file has already been processed." : (res?.data?.message || 'Something went wrong!'));
        }
        handleDocDialogClose();
        setFile({});
      }
    } catch (err) {
      toast.dismiss(uploadToast);
    }
  };
  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };
  const handleStatusChange = (e, newValue) => {
    setAutoCompleteValue((prev) => {
      return { ...prev, verification_output: newValue };
    });
    setFilter((prev) => {
      return {
        ...prev,
        verification_output: newValue.map((item) => item.value),
      };
    });
  };
  const handleVisaTypeChange = (e, newValue) => {
    setAutoCompleteValue((prev) => {
      return { ...prev, visa_type: newValue };
    });
    setFilter((prev) => {
      return {
        ...prev,
        visa_type: newValue.map((item) => item.value),
      };
    });
  };
  const handleStartDateChange = (e) => {
    setFilter((prev) => {
      return {
        ...prev,
        from: e.target.value,
        to: "",
      };
    });
  };
  const handleEndDateChange = (e) => {
    setFilter((prev) => {
      return {
        ...prev,
        to: e.target.value,
      };
    });
  };
  const handleApplyFilter = () => {
    setScanDocPayload((prev) => {
      return {
        params: {
          ...prev.params,
          ...filter,
          created_at:"",
          sk: "",
          username: "",
          page_size: 5,
        },
        isFiltered: true,
      };
    });
    handleFilterClose();
  };
  const handleRemoveFilter = () => {
    setFilter(filterInitialState);
    setAutoCompleteValue({
      verification_output: [],
      visa_type: [],
    });
    setScanDocPayload((prev) => {
      return {
        params: {
          receipt_number: prev.params.receipt_number,
          page_size: prev.page_size || 5,
        },
        isFiltered: false,
      };
    });
    handleFilterClose();
  };
  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const uploadedFile = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = () => {
        let file = {
          name: uploadedFile.name,
          value: reader.result,
          fileObj: uploadedFile,
        };
        if (uploadedFile.type === "application/pdf") {
          setFile({
            type: "pdf",
            ...file,
          });
        } else if (
          uploadedFile.type === "image/jpeg" ||
          uploadedFile.type === "image/png"
        ) {
          setFile({ type: "image", ...file });
        } else if (
          uploadedFile.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
          uploadedFile.type === "application/vnd.ms-excel"
        ) {
          setFile({ type: "excel", ...file });
        } else if (uploadedFile.type === "text/csv") {
          setFile({ type: "csv", ...file });
        } else if (
          uploadedFile.type === "application/msword" ||
          uploadedFile.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          setFile({ type: "doc", ...file });
        } else {
          toast.error("Unsupported file type!");
        }
      };
      reader.readAsDataURL(uploadedFile);
    }
  };
  const handleSearch = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length > 2) {
      setScanDocPayload((prev) => {
        return {
          ...prev,
          params: {
            ...prev.params,
            sk: "",
            username: "",
            receipt_number: inputValue.toUpperCase(),
          },
        };
      });
    } else if (inputValue.length === 0) {
      setScanDocPayload((prev) => {
        return {
          ...prev,
          params: {
            ...prev.params,
            receipt_number: "",
            sk: "",
            username: "",
          },
        };
      });
    }
  };
  const handleRefreshJobs = () => {
    dispatch(getJobsAsynThunk())
      .unwrap()
      .then((res) => {
        dispatch(scanDocAsynThunk(scanDocPayload));
        dispatch(getDocCountsAsynThunk());
        toast.success("Refreshed Successfully!");
        setJobsData(res.data.Items);
      })
      .catch(() => {});
  };
  const handleDebouncedSearch = debounce(handleSearch, 1000);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {},
    onDrop,
  });

  // -----------------UseEffects----------------- //
  useEffect(() => {
    dispatch(scanDocAsynThunk(scanDocPayload));
    // eslint-disable-next-line
  }, [scanDocPayload]);
  useEffect(() => {
    dispatch(getDocCountsAsynThunk());
    dispatch(getJobsAsynThunk())
      .unwrap()
      .then((res) => {
        setJobsData(res.data.Items);
      })
      .catch(() => {});
    // eslint-disable-next-line
  }, [jobnCountApi]);
  useEffect(() => {
    setDocDetailsData(documentList.all[docDetailsInd]);
    // eslint-disable-next-line
  }, [docDetailsInd]);

  // -----------------Context----------------- //
  const dashboardContextValue = {
    doc_counts,
    showStatus,
    docDialog,
    setDocDialog,
    handleDocDialogOpen,
    handleDocDialogClose,
    docDetailsData,
    handleUpload,
    docDetailsDialog,
    handleDocDetailsDialogOpen,
    handleDocDetailsDialogClose,
    docDetailsInd,
    documentList,
    file,
    setFile,
    isUser,
    dispatch,
    scanDocPayload,
    setScanDocPayload,
    documentListStatus,
    handleRemoveFilter,
    handleApplyFilter,
    handleEndDateChange,
    handleStartDateChange,
    handleVisaTypeChange,
    handleStatusChange,
    handleFilterClose,
    handleFilterClick,
    autoCompleteValue,
    filterAnchorEl,
    filter,
    getRootProps,
    getInputProps,
    jobsData,
    handleDebouncedSearch,
    setDocDetailsDialog,
    setDocDetailsData,
    pendingJobsData,
    openPendingJobsDialog,
    handlePendingJobsDialogOpen,
    handlePendingJobsDialogClose,
    handleRefreshJobs,
  };
  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <DashboardTemplate />
    </DashboardContext.Provider>
  );
};
