import { METHODS, SERVICE_URL } from "utils/constants";
import { AppClient, AuthClient } from "utils/network";
import { filterEmptyKeys, replaceParams } from "utils/helper";

export const presignedUrlService = (payload) => {
  const { params } = payload;
  return AppClient({
    url: SERVICE_URL.GET_PRESIGNED_URL,
    method: METHODS.GET,
    data: {},
    params,
  });
};

export const uploadFileUrlService = (data) => {
  const { pdfFormData, url } = data;
  return AuthClient({
    url: url,
    method: METHODS.PUT,
    data: pdfFormData,
  });
};

export const validateDocService = (data) => {
  return AppClient({
    url: SERVICE_URL.VALIDATE_DOCUMENT,
    method: METHODS.POST,
    data,
  });
};

export const scanDocService = (payload) => {
  return AppClient({
    url: SERVICE_URL.SCAN_DOCUMENT,
    method: METHODS.GET,
    params: filterEmptyKeys(payload?.params || {}),
  });
};

export const docCountsService = () => {
  return AppClient({
    url: SERVICE_URL.DOC_COUNTS,
    method: METHODS.GET,
  });
};

export const getJobsService = () => {
  return AppClient({
    url: SERVICE_URL.JOBS,
    method: METHODS.GET,
  });
};

export const getDocByJobId = (data) => {
  return AppClient({
    url: replaceParams(SERVICE_URL.DOCUMENT_BY_JOB_ID, data),
    method: METHODS.GET,
  });
};
