import { Box, IconButton, Stack, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { RecentDocCard } from ".";
import { DashboardContext } from "utils/contexts";
import RefreshRoundedIcon from "@mui/icons-material/RefreshRounded";

export const CurrentDocStatus = () => {
  const { jobsData, handlePendingJobsDialogOpen, handleRefreshJobs, isUser } =
    useContext(DashboardContext);

  // Temp Sorting according to date
  const sortedJobsData = jobsData.sort((a, b) => b.created_at - a.created_at);
  return (
    <Box sx={{position:'relative'}}>
      <Box sx={{position:'absolute',right:0,display:'flex',justifyContent:'flex-end'}}>
        <Tooltip title="Refresh Jobs" arrow placement="left">
          <IconButton onClick={handleRefreshJobs}>
            <RefreshRoundedIcon color={"pending"} />
          </IconButton>
        </Tooltip>
      </Box>
      <Box
        sx={{ display:'flex',overflowX: "auto", width: '100%', pb: 1,mt:2 }}
        gap={2}
      >
        {sortedJobsData.map((job, ind) => {
          return (
            <RecentDocCard
              onClick={() => handlePendingJobsDialogOpen(job)}
              key={ind}
              {...job}
            />
          );
        })}
      </Box>
    </Box>
  );
};
