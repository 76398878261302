import React, { useEffect, useState } from "react";
import { ExportsContext } from "utils/contexts";
import { ExportsTemplate } from "../templates";
import { useDispatch, useSelector } from "react-redux";
import {getExportListAsyncThunk} from "redux/asyncThunk";
import { debounce, showStatus } from "utils/helper";
import moment from "moment";
import toast from "react-hot-toast";
import { exportFilename, formattedExcelData } from "utils/data";
import { exportToExcel } from 'react-easy-export';

export const Exports = () => {
  const filterInitialState = {
    from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    to: moment().subtract(0, 'days').format('YYYY-MM-DD'),
    verification_output: null,
    visa_type: null,
  };

  const dispatch = useDispatch();
  const { exportDocuments,exportDocumentStatus } = useSelector(
    (state) => state.export
  );
  const [docDialog, setDocDialog] = useState(false);
  const [docDetailsDialog, setDocDetailsDialog] = useState(false);
  const [docDetailsInd, setDocDetailsInd] = useState(-1);
  const [docDetailsData, setDocDetailsData] = useState({});
  const [autoCompleteValue, setAutoCompleteValue] = useState({
    verification_output: [],
    visa_type: [],
  });
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [filter, setFilter] = useState(filterInitialState);
  const [actualFilter, setActualFilter] = useState(filterInitialState);

  useEffect(() => {
    if(actualFilter.from && actualFilter.to){
      dispatch(getExportListAsyncThunk(actualFilter));
    }
  }, []);

  useEffect(() => {
    setDocDetailsData(exportDocuments[docDetailsInd]);
  }, [docDetailsInd]);

  const handleDocDialogOpen = () => {
    setDocDialog(true);
  };

  const handleDocDialogClose = () => {
    setDocDialog(false);
  };

  const handleDocDetailsDialogOpen = (ind) => {
    setDocDetailsDialog(true);
    setDocDetailsInd(ind);
  };

  const handleDocDetailsDialogClose = () => {
    setDocDetailsDialog(false);
  };

  const handleStatusChange = (e, newValue) => {
    setAutoCompleteValue((prev) => {
      return { ...prev, verification_output: newValue };
    });
    setFilter((prev) => {
      return {
        ...prev,
        verification_output: newValue.map((item) => item.value),
      };
    });
  };
  const handleVisaTypeChange = (e, newValue) => {
    setAutoCompleteValue((prev) => {
      return { ...prev, visa_type: newValue };
    });
    setFilter((prev) => {
      return {
        ...prev,
        visa_type: newValue.map((item) => item.value),
      };
    });
  };

  const handleStartDateChange = (e) => {
    setFilter((prev) => {
      return {
        ...prev,
        from: e?.target?.value || "",
        to: "",
      };
    });
  };
  const handleEndDateChange = (e) => {
    var a = moment(filter.from);
    var b = moment(e.target.value);

    const dateDiff = b.diff(a, 'days');
    if(dateDiff<180){
      setFilter((prev) => {
        return {
          ...prev,
          to: e?.target?.value || "",
        };
      });
    }else{
      toast.error('The disparity between dates must not exceed a duration of 180 days!')
    }
  };

  const handleSearch = (event) => {
    const inputValue = event.target.value;
    const payload1={
      ...filter,
      sk: "",
      username: "",
      created_at: "",
      receipt_number: inputValue.toUpperCase(),
    }
    const payload2={
      ...filter,
      sk: "",
      username: "",
      created_at: "",
      receipt_number: "",
    }
    if (inputValue.length > 2) {
      setFilter(payload1);
      dispatch(getExportListAsyncThunk(payload1));
    } else if (inputValue.length === 0) {
      setFilter(payload2);
      dispatch(getExportListAsyncThunk(payload2));
    }
    handleFilterClose();
  };

  const handleDebouncedSearch = debounce(handleSearch, 1000);

  const handleApplyFilter = () => {
    setActualFilter((prev) => {
      return {
        ...filter,
        sk: "",
        username: "",
      };
    });
    dispatch(getExportListAsyncThunk({
      ...filter,
      sk: "",
      username: "",
    }));
    handleFilterClose();
  };

  const handleRemoveFilter = () => {
    setFilter(filterInitialState);
    setActualFilter(filterInitialState);
    setAutoCompleteValue({
      verification_output: [],
      visa_type: [],
    });
    dispatch(getExportListAsyncThunk(filterInitialState));
    handleFilterClose();
  };

  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };
  const handleFilterClose = () => {
    setFilterAnchorEl(null);
  };

  const handleExport = () =>{
    const filename = exportFilename(filter);
    const payload = formattedExcelData(exportDocuments);
    exportToExcel(payload, filename);
  }

  const exportContextValue = {
    handleDocDialogOpen,
    handleDocDialogClose,
    docDetailsData,
    docDetailsDialog,
    handleDocDetailsDialogOpen,
    handleDocDetailsDialogClose,
    showStatus,
    dispatch,
    handleDebouncedSearch,
    exportDocuments,
    handleSearch,
    autoCompleteValue,
    exportDocumentStatus,
    filterAnchorEl,
    filter,
    actualFilter,
    handleStatusChange,
    handleVisaTypeChange,
    handleFilterClick,
    handleFilterClose,
    handleApplyFilter,
    handleRemoveFilter,
    handleStartDateChange,
    handleEndDateChange,
    handleExport
  }
  return (
    <ExportsContext.Provider value={exportContextValue}>
      <ExportsTemplate />
    </ExportsContext.Provider>
  );
};
