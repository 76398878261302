import * as React from "react";
import { Stack, Typography, IconButton, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import CrossIcon from "assets/images/icons/cross-sec.svg";
import { DashboardContext } from "utils/contexts";
import { useContext } from "react";
import { FileUploader } from ".";
import _ from "lodash";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function UploadDocDialog() {
  const { docDialog, file, handleDocDialogClose, handleUpload } =
    useContext(DashboardContext);

  return (
    <Dialog
      open={docDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleDocDialogClose}
      aria-describedby="alert-dialog-slide-description"
      maxWidth={"xl"}
      PaperProps={{ sx: { borderRadius: "50px" } }}
    >
      <Stack px={{ xs: 2, md: 6.5 }} py={5.5}>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack>
            <Typography variant="h3">Upload Center</Typography>
            <Typography variant="subtitle2">
              Drag and drop your file here to start uploading
            </Typography>
          </Stack>
          <Stack>
            <IconButton onClick={handleDocDialogClose}>
              <img width={20} alt={"cross icon"} src={CrossIcon} />
            </IconButton>
          </Stack>
        </Stack>
        <Stack
          p={3}
          direction={{ xs: "column", lg: "row" }}
          alignItems={"center"}
          gap={6}
        >
          <Stack flex={1} gap={2} direction={{ xs: "column", sm: "row" }}>
            <FileUploader />
          </Stack>
        </Stack>
        <Stack alignItems={{ xs: "center", sm: "flex-end" }} px={3}>
          <Button
            sx={{
              px: 5.5,
              background: (theme) => theme.palette.primary.light,
              fontSize: "20px",
            }}
            variant="contained"
            onClick={handleUpload}
            disabled={_.isEmpty(file)}
          >
            Upload
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
}
