import { Navigate } from "react-router-dom";
import { AuthLayout } from "../layout/authLayout/auth.layout";
import { Login, Home } from "../UI/pages";
import { ROUTES } from "utils/constants";

// Define main application routes
export const authRoutes = [
  {
    element: <AuthLayout />,
    children: [
      { path: ROUTES.BASE, element: <Home />, exact: true },
      { path: ROUTES.LOGIN, element: <Login />, exact: true },
      {
        path: ROUTES.ALL_ROUTES,
        element: <Navigate to={ROUTES.BASE} />,
        exact: true,
      },
    ],
  },
];
