import React, { useContext } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Button,
  Divider,
  IconButton,
  Grid,
  TextField,
  Badge,
  Box,
} from "@mui/material";
import { style } from "./style";
import { AutocompleteAtom } from "UI/atoms";
import {roleStatusData } from "utils/data";
import _ from "lodash";
import { RolesContext } from "utils/contexts/dashboardContext";

export function RoleFilterPopover() {
  const { 
    filter,
    applyFilter,
    removeFilter,
    filterAnchorEl,
    handleFilterClick,
    handleFilterClose,
    handleStatusChange,
    handleStartDateChange,
    handleEndDateChange,
    autoCompleteValue,
   } = useContext(RolesContext);
  return (
    <Box display='flex' alignItems='center' justifyContent='center'>
      <IconButton onClick={handleFilterClick}>
        {(filter?.role || []).length || (filter.from && filter.to)? (
          <Badge color="error" variant="dot">
            <FilterListIcon />
          </Badge>
        ) : (
          <FilterListIcon />
        )}
      </IconButton>
      <Popover
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid container sx={style.filterPopover}>
          <Grid item xs={12}>
            <Typography variant="h5">Filter List</Typography>
            <Divider />
          </Grid>
          <Grid container pt={2} spacing={2}>
            <Grid item xs={12}>
              <AutocompleteAtom
                label={"Role"}
                options={roleStatusData}
                onChange={handleStatusChange}
                value={autoCompleteValue.role}
              />
            </Grid>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{ bgcolor: "primary.light" }}
                  onClick={applyFilter}
                  disabled={!(filter?.role || []).length}
                >
                  Apply Filters
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  color="error"
                  onClick={removeFilter}
                  disabled={!(filter?.role || []).length}
                >
                  Remove Filters
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </Box>
  );
}
