import React from "react";
import { HomeContext } from "utils/contexts";
import { HomeTemplate } from "../templates";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants";
import { useMediaQuery, useTheme } from "@mui/material";

export const Home = () => {
  // -----------------Hooks----------------- //
  const navigate = useNavigate();
  const theme = useTheme();

  // -----------------Variables----------------- //
  const isMatchMd = useMediaQuery(theme.breakpoints.down("md"));
  const isMatchSM = useMediaQuery(theme.breakpoints.down("sm"));

  // -----------------Functions----------------- //
  const NavigateToLogin = () => {
    navigate(ROUTES.LOGIN);
  };

  // -----------------Contexts----------------- //
  const HomeContextValue = {
    NavigateToLogin,
    isMatchMd,
    isMatchSM,
  };
  return (
    <HomeContext.Provider value={HomeContextValue}>
      <HomeTemplate />
    </HomeContext.Provider>
  );
};
