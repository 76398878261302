import { createSlice } from "@reduxjs/toolkit";
import { getAuthTokenAsyncThunk } from "redux/asyncThunk";
import { THUNK_STATUS } from "utils/constants";
import { jwtDecode } from "jwt-decode";

const initialState = {
  user: {},
  token: null,
  loginStatus: null,
  registerStatus: null,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Reducer for user logout
    logout: (state) => {
      state.user = null;
      state.token = null;
      window.location.reload();
      localStorage.clear();
    },
    // set token temp
    setToken: (state, action) => {
      state.user = { role: "user" };
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuthTokenAsyncThunk.pending, (state) => {
        state.loginStatus = THUNK_STATUS.PENDING;
      })
      .addCase(getAuthTokenAsyncThunk.fulfilled, (state, action) => {
        if (action.payload?.data.token) {
          state.token = action.payload?.data.token;

          let decode = jwtDecode(action.payload?.data.token);
          state.user = decode.data;
        }
        state.loginStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(getAuthTokenAsyncThunk.rejected, (state, action) => {
        state.loginStatus = THUNK_STATUS.REJECTED;
      });
  },
});

export default AuthSlice.reducer;
export const authState = (state) => AuthSlice;
export const { logout, setToken } = AuthSlice.actions;
