import { InputAdornment, InputBase, Stack } from "@mui/material";
import SearchIcon from "../../assets/images/icons/search.svg";
import React from "react";
import { muiSx } from "../../themes/styles";

export const SearchInput = (props) => {
  const { label, fullWidth, sx, ...rest } = props;
  return (
    <InputBase
      fullWidth={fullWidth}
      placeholder={label}
      inputProps={{ "aria-label": label }}
      sx={{ ...muiSx.searchInputAtom, ...sx }}
      endAdornment={
        <InputAdornment position="end">
          <img src={SearchIcon} />
        </InputAdornment>
      }
      {...rest}
    />
  );
};
