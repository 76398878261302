import React, { useContext } from "react";
import { Stack, Typography } from "@mui/material";
import { LoginContext } from "utils/contexts";
import { style } from "./style";
import { GoogleLogin as GoogleLoginButton } from "@react-oauth/google";
export const GoogleLogin = () => {
  const { onSuccess, onError } = useContext(LoginContext);
  return (
    <Stack sx={style.loginStack}>
      <Stack sx={style.loginInnerStack}>
        <Stack>
          <Typography
            variant="h2"
            color="primary"
            sx={{
              textAlign: "center",
            }}
          >
            Welcome back!
          </Typography>
          <Typography
            mt={2}
            variant="subtitle1"
            sx={{
              textAlign: "center",
            }}
          >
            **Search for profiles using I-797 Receipt Numbers to make sure they
            are valid.
          </Typography>
        </Stack>
        <Stack alignItems={"center"}>
          <GoogleLoginButton onError={onError} onSuccess={onSuccess} />
        </Stack>

        <Typography variant="subtitle1" sx={style.disclaimer}>
          Disclaimer: Vvalidate does not maintain copies of DHS approval notices
          and does not warrant its results. Our software evaluates whether a DHS
          approval notice resembles an actual approval notice issued by the
          Department of Homeland Security. This product should be used at an
          employer’s discretion. Customers should ultimately make hiring
          decisions based on their confidence in the authenticity and validity
          of the Department of Homeland Security approval notice, not solely
          based on our software. Vvalidate is not a substitute for the
          Department of Homeland Security’s Everify system.
        </Typography>
      </Stack>
    </Stack>
  );
};
