import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { SearchInput, ButtonAtom } from "../atoms";
import { muiSx } from "../../themes/styles";
import { RoleFilterPopover } from "./RolefilterPopover";
import { RolesContext } from "utils/contexts/dashboardContext";

export const RoleHeader = () => {
  const { handleDebouncedSearch } = useContext(RolesContext);

  return (
    <Grid container sx={[muiSx.dashboardHeaderStack,{py:2,mt:2}]} alignItems='center' >
      <Grid container md={4} xs={12} alignItems='center'>
        <Grid item sm={10} xs={11}>
          <SearchInput
            label={"Search"}
            sx={muiSx.searchInput}
            onChange={handleDebouncedSearch}
          />
        </Grid>
        <Grid item sm={2} xs={1} alignItems='center'>
          <RoleFilterPopover />
        </Grid>
      </Grid>
    </Grid>
  );
};
