import { Stack, Typography } from "@mui/material";
export const StatusData = (props) => {
  const { Icon, color, status } = props;
  return (
    <Stack
      flex={1}
      sx={{ color: color }}
      direction={"row"}
      spacing={1.5}
      alignItems={"center"}
    >
      {"("}
      <Icon />
      {")"}
      <Typography sx={{ textTransform: "capitalize" }} component="span">
        {status}
      </Typography>
    </Stack>
  );
};
