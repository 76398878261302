// material-ui
import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (mode) => {
  return createTheme({
    palette: {
      mode,
      common: {
        black: "#000",
        white: "#fff",
      },
      primary: {
        light: "#AF60F2",
        main: "#8213E0",
        darker: "#321B59",
        contrastText: "#ffffff",
      },
      secondary: {
        light: "#203e70",
        main: "#203e70",
        dark: "#203e70",
      },
      error: {
        main: "#B2485B",
      },
      success: {
        main: "#4D751C",
      },
      warning: {
        main: "#A0B70F",
        contrastText: "#ffffff",
      },
      pending: {
        light: grey[200],
        main: grey[500],
        dark: grey[800],
        contrastText: "#000",
      },
    },
  });
};

export default Palette;
