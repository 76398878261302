import { IconButton, Stack, CircularProgress, Box, Select, MenuItem, Button } from "@mui/material";
import { Waypoint } from "react-waypoint";
import { formatDateWithDateObject } from "utils/helper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { THUNK_STATUS } from "utils/constants";
import { roleStatusData } from "..";
import { Fragment } from "react";

export const dashboardTableColumns = (
  showStatus,
  tableData,
  handleDocDetailsDialogOpen,
  setScanDocPayload,
) => {
  const tableParams = tableData.current?.LastEvaluatedKey;
  const params = {
    sk: tableParams?.sk,
    username: tableParams?.username,
    created_at: tableParams?.created_at
  };
  return [
    {
      name: "receipt_number",
      label: "Receipt No.",
      options: {
        filter: false,
        customBodyRender: (value, meta) => {
          let rowIndex = meta.rowIndex;
          if (
            rowIndex === tableData.all.length - 1 &&
            tableParams?.sk &&
            tableParams?.username &&
            tableParams?.created_at
          ) {
            return (
              <>
                <Waypoint
                  onEnter={({ previousPosition, currentPosition, event }) => {
                    setScanDocPayload((prev) => {
                      return {
                        ...prev,
                        params: {
                          ...prev.params,
                          ...params,
                          page_size: 5,
                        },
                      };
                    });
                  }}
                />
                {value}*
              </>
            );
          } else {
            return <>{value}</>;
          }
        },
      },
    },
    {
      name: "name",
      label: "Candidate Name",
      options: {
        filter: true,
        filterType: "dropdown",
        filterOptions: { fullWidth: true },
      },
    },
    {
      name: "created_at",
      label: "Upload date",
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <>{formatDateWithDateObject(value)}</>;
        },
      },
    },
    {
      name: "visa_type",
      label: "Visa type",
      options: {
        filter: true,
        filterType: "dropdown",
        customBodyRender: (value) => {
          return <Box sx={{ whiteSpace: "nowrap" }}>{value}</Box>;
        },
      },
    },
    {
      name: "verification_output",
      label: "Result",
      options: {
        filter: true,
        filterType: "dropdown",
        sort: false,
        setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
        customBodyRender: (value, meta) => {
          let rowIndex = meta.rowIndex;
          return (
            <Stack
              sx={{ whiteSpace: "nowrap" }}
              direction={"row"}
              spacing={1}
              alignItems={"center"}
            >
              {showStatus(value)}
              {(value || "").toLowerCase() !== "pending" && (
                <IconButton
                  onClick={() => handleDocDetailsDialogOpen(rowIndex)}
                >
                  <VisibilityIcon />
                </IconButton>
              )}
            </Stack>
          );
        },
      },
    },
  ];
};

export const dashboardTableOptions = (documentListStatus) => {
  return {
    tableBodyMaxHeight: "600px",
    textLabels: {
      body: {
        noMatch:
          documentListStatus === THUNK_STATUS.PENDING ? (
            <CircularProgress />
          ) : (
            "Sorry, there is no data to display"
          ),
      },
    },
  };
};

export const rolesTableColumns = (activeId,applyingRole,handleApplyRole,setScanDocPayload,tableData) => {
  const tableParams = tableData?.current?.LastEvaluatedKey || {};
  const params = {
    sk: tableParams?.sk,
    username: tableParams?.username,
    created_at: tableParams?.created_at
  };
  return [
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
        setCellProps: () => ({ style: { textAlign: "left"}}),
        customBodyRender: (value, meta) => {
          let rowIndex = meta.rowIndex;
          if (
            rowIndex === tableData.all.length - 1 &&
            tableParams?.sk &&
            tableParams?.username &&
            tableParams?.created_at
          ) {
            return (
              <Fragment>
                <Waypoint
                  onEnter={({ previousPosition, currentPosition, event }) => {
                    setScanDocPayload((prev) => {
                      return {
                        ...prev,
                        ...params,
                      };
                    });
                  }}
                />
                {value}
              </Fragment>
            );
          } else {
            return <>{value}</>;
          }
        },
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        filter: false,
        setCellHeaderProps: () => ({ style: { textAlign: "left",width:250 } }),
        customBodyRender: (value,tableMeta,updateValue) => {
          if((tableMeta?.tableData || []).length && activeId===tableMeta?.tableData[tableMeta?.rowIndex]?.email && applyingRole){
            return <CircularProgress />
          }
          return (
            <Box sx={{width:'100%',display:'flex',alignItems:'center',gap:1,margin:"0 auto"}}>
              <Select
                value={value || ""}
                onChange={e => updateValue(e.target.value)}
                sx={{width:'100%'}}
              >
                {/* <MenuItem value="" disabled>Select role</MenuItem> */}
                {roleStatusData.map((status,statusIndex)=>{
                  return <MenuItem value={status.value} key={statusIndex}>{status?.title || ""}</MenuItem>;
                })};
              </Select>
              {(tableMeta?.tableData || []).length && tableMeta?.tableData[tableMeta?.rowIndex]?.role!==value
                ?
                <Button onClick={()=>handleApplyRole(value,tableMeta?.tableData[tableMeta?.rowIndex]?.email)}>
                  Apply
                </Button>
              :
              null}
            </Box>
          );
        },
      },
    },
    {
      name: "created_at",
      label: "Date",
      options: {
        filter: false,
        setCellHeaderProps: () => ({ style: { textAlign: "left" } }),
        setCellProps: () => ({ style: { textAlign: "left"}}),
        customBodyRender: (value) => {
          return <Fragment>{formatDateWithDateObject(value)}</Fragment>;
        },
      },
    },
  ];
};

export const rolesTableOptions = (rolesStatus) => {
  return {
    tableBodyMaxHeight: "600px",
    textLabels: {
      body: {
        noMatch:
          rolesStatus === THUNK_STATUS.PENDING ? (
            <CircularProgress />
          ) : (
            "Sorry, there is no data to display"
          ),
      },
    },
  };
};

