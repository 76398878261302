import React from "react";
import { Container, Link, Stack } from "@mui/material";
import WhiteLogo from "assets/images/vvalidate-logo-white.svg";
import LinkedIn from "assets/images/icons/linkedin.svg";
import { muiSx } from "themes/styles";

export const Footer = () => {
  return (
    <Container component="section" id="examples" maxWidth="xl">
      {/* <Stack sx={style.footerBox}>
        <Stack direction={"row"} flexWrap={"wrap"} gap={2}>
          <Stack gap={2} flex={1} alignItems={"flex-start"}>
            <Typography variant="subtitle1" sx={style.footerMenuTitle}>
              V-Validate
            </Typography>
            {VvalidateFooterLinks.map((link, ind) => {
              return (
                <Link component={"span"} key={ind} sx={style.footerMenuLink}>
                  {link.text}
                </Link>
              );
            })}
          </Stack>
          <Stack gap={2} flex={1} alignItems={"flex-start"}>
            <Typography variant="subtitle1" sx={style.footerMenuTitle}>
              Information
            </Typography>
            {InformationFooterLinks.map((link, ind) => {
              return (
                <Link key={ind} sx={style.footerMenuLink}>
                  {link.text}
                </Link>
              );
            })}
          </Stack>
          <Stack gap={2} flex={1} alignItems={"flex-start"}>
            <Typography variant="subtitle1" sx={style.footerMenuTitle}>
              Company
            </Typography>
            {CompanyFooterLinks.map((link, ind) => {
              return (
                <Link key={ind} sx={style.footerMenuLink}>
                  {link.text}
                </Link>
              );
            })}
          </Stack>
          <Stack flex={1.3}>
            <Stack sx={style.subsBox} gap={2}>
              <Typography variant="subtitle1" sx={style.subsTitle}>
                Subscribe
              </Typography>
              <InputBase
                placeholder={"Email address"}
                sx={style.subsEmail}
                endAdornment={
                  <InputAdornment sx={style.subsEmailIcon} position="end">
                    <EastIcon />
                  </InputAdornment>
                }
              />
              <Typography variant="subtitle1" sx={style.subsDesc}>
                Hello, we are V-Validate. Our goal is to assist our clients in
                verifying data and making validation simple and fast.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack> */}
      <Stack
        direction="row"
        flexWrap={"wrap"}
        alignItems={"center"}
        justifyContent={"space-between"}
        py={3}
        sx={{ borderTop: "1px solid rgba(255,255,255,0.2)" }}
        gap={2}
      >
        <Stack flex={1} alignItems={{ xs: "center", sm: "flex-start" }}>
          <img src={WhiteLogo} alt={"logo"} style={{width:"200px",objectFit:"contain"}}/>
        </Stack>
        <Stack
          direction={"row"}
          flex={1}
          gap={4}
          justifyContent={{ xs: "center", md: "flex-end" }}
        >
          <Link
            href="https://www.linkedin.com/company/thisisvvalidate/"
            sx={muiSx.footerIcon}
          >
            <img src={LinkedIn} alt={"linkedin"} />
          </Link>
        </Stack>
      </Stack>
    </Container>
  );
};
