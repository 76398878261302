import UpArrow from "assets/images/up-arrow.svg";

export const muiSx = {
  currentDocCard: {
    background: "white",
    p: 1,
    borderRadius: 2,
    minWidth: "250px",
    gap: 0.5,
    cursor: "pointer",
    maxWidth: "400px"
  },
  showxsHidelg: {
    display: { xs: "flex", md: "none" },
  },
  blackIcon: {
    color: (theme) => theme.palette.common.black,
  },
  secondaryHeader: {
    // background: (theme) => theme.palette.common.white,
    // borderRadius: "25px",
  },
  searchInput: { width: { xs: "100%" } },
  uploadButton: {
    background: (theme) => theme.palette.primary.light,
    fontSize: "18px",
  },
  dateInputAtom: {
    height: "50px",
    px: 3.5,
    borderRadius: "5px",
  },
  searchInputAtom: {
    height: "50px",
    px: 3.5,
    borderRadius: "25px",
    background: "#F6F4F4",
  },
  alignLeft: {
    textAlign: "left",
  },
  noWrap: { whiteSpace: "nowrap" },
  tableTopBorder: {
    borderTop: "1px solid #D6E0E6",
    overflow: "auto",
    mt: { xs: 2, md: 6 },
  },
  mainLayoutBox: { background: "#EBEBEE",minHeight:'100vh' },
  mainLayoutContainer: {
    flexDirection: { xs: "column", md: "row" },
    my: 3,
    mx: { xs: 2, sm: 4.5 },
    gap: 3,
  },
  sidebar: {
    background: (theme) => theme.palette.common.white,
    minHeight:'88vh',
    width: '100px',
    display: { xs: "none", md: "flex" },
    flexDirection:'column',
    alignItems:'center',
    justifyContent:'space-between',
    px: 1,
    py: 2,
    gap:1.5,
  },
  sideBarButtons:{
    width:50,
    height:50,
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:'transparent',
    cursor: 'pointer',
    color:'#000',
    fontWeight:600
  },
  sideBarButtonsActive:{
    width:50,
    height:50,
    borderRadius:2,
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor:'#AF06F2',
    cursor: 'default',
    color:'#fff',
    fontWeight:600
  },
  howItWorksBackground: {
    position: "relative",
    "&::before": {
      content: `url(${UpArrow})`,
      position: "absolute",
      top: "35px",
      right: "35px",
      zIndex: -1,
    },
    "&::after": {
      content: `url(${UpArrow})`,
      position: "absolute",
      bottom: "0",
      left: "35px",
      zIndex: -1,
    },
    mb: 6,
  },
  hiwNexampleContainer: {
    background:
      "radial-gradient(50% 50% at 50% 50%, #DECCED 0%, rgba(217, 217, 217, 0.00) 100%)",
  },
  footerIcon: {
    p: 1.5,
    width: 50,
    height: 50,
    borderRadius: "50%",
    border: "1px solid rgba(255,255,255,0.4)",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  webHeadContainer: {
    display: { xs: "none", md: "flex" },
    py: "29px",
    flexDirection: "row",
    alignItems: "center",
    columnGap: 8,
  },
  dashboardHeaderStack: {
    // px: { xs: 2, sm: 5 },
    // pt:3,
    // pb:2,
    px:2,
    pt:1,
    background: (theme) => theme.palette.common.white,
    borderRadius: 2,
  },
  tableBox: {
    background: (theme) => theme.palette.common.white,
    borderRadius: 2,
    px: { xs: 4, md: 7 },
    py: 1,
    minHeight:'48vh',
  },
  loginBackground: (isLoginRoute) => ({
    position: "fixed",
    top: 0,
    width: "45%",
    zIndex: -1,
    height: "100vh",
    right: 0,
    background: isLoginRoute ? (theme) => theme.palette.primary.light : "none",
    borderRadius: "320px 25px 25px 320px",
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
    alignItems: "center",
  }),
  headMobContainer: {
    display: { xs: "flex", md: "none" },
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    py: 3,
    flexWrap: "nowrap",
  },
  navFont: {
    fontWeight: "600",
    color: "#424B5A",
    fontSize: 17,
    cursor: "pointer",
  },
  mainHeadContainer: {
    background: (theme) => theme.palette.common.white,
    height: "110px",
    alignItems: "center",
    px: { xs: 2, sm: 4.5 },
  },
  mainHeadMenuBar: {
    display: { xs: "none", md: "flex" },
    justifyContent: "flex-end",
  },
  sideBarContainer: { cursor: "pointer", flexDirection: "row", gap: 1 },
};
