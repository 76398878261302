import { METHODS, SERVICE_URL } from "../../utils/constants";
import { AppClient, AuthClient, GoogleClient } from "../../utils/network";

// Function for making a login API request
export const loginService = (data) => {
  return AuthClient({
    url: SERVICE_URL.LOGIN,
    method: METHODS.POST,
    data,
  });
};

export const authTokenService = (payload) => {
  const { token } = payload;
  return AppClient({
    url: SERVICE_URL.AUTH_TOKEN,
    method: METHODS.GET,
    headers: { token },
  });
};

export const getProfileService = () => {
  return GoogleClient({
    url: SERVICE_URL.USER_PROFILE,
    method: METHODS.GET,
  });
};
