import { createSlice } from "@reduxjs/toolkit";
import { applyUserRoleAsyncThunk, getUserListAsyncThunk } from "redux/asyncThunk";
import { THUNK_STATUS } from "utils/constants";

const initialState = {
  rolesStatus: null,
  userList: {
    current: {},
    all: [],
  },
};

export const RolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserListAsyncThunk.pending, (state) => {
        state.rolesStatus = THUNK_STATUS.PENDING;
      })
      .addCase(getUserListAsyncThunk.fulfilled, (state, action) => {
        let data = action.payload.data?.Items || [];
        data = data.filter(item=>Object.keys(item).length>1);
        if (
          action.payload.config.params?.sk &&
          action.payload.config.params?.username &&
          action.payload.data?.Items
        ) {
          state.userList.all.push(...data);
        } else {
          state.userList.all = data;
        }
        state.userList.current = action.payload.data;
        state.rolesStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(getUserListAsyncThunk.rejected, (state, action) => {
        state.rolesStatus = THUNK_STATUS.REJECTED;
      })
      .addCase(applyUserRoleAsyncThunk.pending, (state) => {
        state.rolesStatus = THUNK_STATUS.PENDING;
      })
      .addCase(applyUserRoleAsyncThunk.fulfilled, (state, action) => {
        state.rolesStatus = THUNK_STATUS.SUCCESS;
      })
      .addCase(applyUserRoleAsyncThunk.rejected, (state, action) => {
        state.rolesStatus = THUNK_STATUS.REJECTED;
      })
  },
});

export const authState = (state) => RolesSlice;
export default RolesSlice.reducer;
